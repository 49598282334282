import { AutoColumn, ColumnCenter } from '../Column'
import Row from '../Row'
import { TextWrapper } from '../../theme/components'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

const StyledHeightRow = styled(Row)`
`

export default function ImpermanetLossResult() {
    const theme = useContext(ThemeContext)

    return (
        <StyledHeightRow paddingX={4} paddingY={1} backgroundColor={theme.blue} borderRadius={'20px'}>
            <AutoColumn>
                <TextWrapper fontSize={1}>
                    IMPERMANENT LOSS:
                </TextWrapper>
                <TextWrapper>
                    Asset value hold: 25000
                </TextWrapper>
                <TextWrapper>
                    Impermanent loss: 17%
                </TextWrapper>
            </AutoColumn>
        </StyledHeightRow>
    )
}