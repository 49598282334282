
//INTERFACES
import { DatesInterface, TokenInfo, ChainIdToWrappedTokenInfo, FetchResult, TokenList } from '../../interfaces'

//ENUMS
import { Positions } from '../../constants/enums'

//LIBS
import { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components/macro'
import subMonths from 'date-fns/subMonths'
import subDays from 'date-fns/subDays'
import { getAddress } from '@ethersproject/address'
import { parseUnits } from '@ethersproject/units'

//UTILS
import { WrappedTokenInfo } from '../../utils/WrappedTokenInfo'

//CONSTANTS
import { FocusedToken } from '../../constants/enums'
import { DEFAULT_ACTIVE_LIST_URLS, OPTIMISM_LIST, ARBITRUM_LIST, UNSUPPORTED_LIST_URLS } from '../../constants/tokensLists'
import { FACTORY_ADDRESS as factoryAddress } from '../../constants/addresses'

//COMPONENTS
import AddLiquidity from '../../components/AddLiquidity'
import PriceChartWrapper from '../../components/PriceChart'
import LiquidityChartWrapper from '../../components/LiquidityChart'
import PositionResults from '../../components/PositionResults'
import DefiMathModal from '../../components/Modal'

//HOOKS
import useGetAddress from '../../hooks/useGetAddress'
import { useGetBlockNumbers, useGetSingleBlockNumber } from '../../hooks/useBlockNumbers'
import useFetchTopTokens from '../../hooks/useFetchTopTokens'
import { useFetchPoolData, useFetchPoolDataWithBlock } from '../../hooks/useFetchPoolData'
import { useFetchPoolHourData } from '../../hooks/useFetchPoolHourData'
import useFetchMultiple from '../../hooks/useFetchMultiple'
import useNativeCurrency from '../../hooks/useNativeCurrency'

//UNISWAP CORE
import { Currency, Percent, Token, NativeCurrency } from '@uniswap/sdk-core'

//UNISWAP V3SDK
import { Pool, Position, FeeAmount, computePoolAddress } from '@uniswap/v3-sdk'
import { SupportedChainId } from '../../constants/chains'


const BackTestingWrapper = styled.div`
    color: white;
    width: 100%;
    background: ${({ theme }) => theme.blue };
    border-radius: 10px;
    margin: auto;
    width: 1260px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        max-width: 890px;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 580px;
        max-width: 580px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        min-width: 340px;
        max-width: 340px
    `};
`

const StyledWrapper = styled.div`
    background-color: ${({ theme }) => theme.blue };
    border-radius: 10px; 
    display: grid;
    gap: 8px;
    grid-template-columns: 2fr 3fr 2fr;
    margin: 14px 10px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        grid-template-columns: 2fr 3fr auto;
        grid-template-rows: 1fr auto;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        display: flex;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin: 40px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 10px;
    `};
`

const ChartsWrapper = styled.div`
    display: flex;
    flex-direction: column; 
    gap: 8px;
`

export const ComponentWrapper = styled.div<{ margin?: string, padding?: string }>`
    border: 2px solid rgb(1,179,236);
    margin: ${({ margin }) => margin && margin};
    padding: ${({ padding }) => padding && padding};
    border-radius: 0.5rem;
    flex-shrink: 0;
    background: #000040;
`
type TokenMap = Readonly<{ [tokenAddress: string]: { token: WrappedTokenInfo; list?: TokenList } }>
export type ChainTokenMap = Readonly<{ [chainId: number]: TokenMap }>
type Mutable<T> = {
    -readonly [P in keyof T]: Mutable<T[P]>
}

function useActiveListUrls(chainId: number) {
    const activeLists = useMemo(() => {
        let lists;
        if([SupportedChainId.OPTIMISM].includes(chainId)) {
            lists =  [...DEFAULT_ACTIVE_LIST_URLS, OPTIMISM_LIST]
        } else if([SupportedChainId.ARBITRUM_ONE].includes(chainId)) {
            lists = [...DEFAULT_ACTIVE_LIST_URLS, ARBITRUM_LIST]
        } else {
            lists = DEFAULT_ACTIVE_LIST_URLS
        }
        return lists.filter(list => !UNSUPPORTED_LIST_URLS.includes(list))
    }, [chainId])
  
    return activeLists
}

function tokensToChainTokenMap(tokenList: TokenList) {

    const map = tokenList.tokens.reduce<Mutable<ChainTokenMap>>((map, info) => {
        const token = new WrappedTokenInfo(info, tokenList)
        if(map[token.chainId]?.[token.address] !== undefined) {
            console.warn(`Duplicate token skipped: ${token.address}`)
            return map
        }
        if (!map[token.chainId]) {
            map[token.chainId] = {}
          }
          map[token.chainId][token.address] = { token, list: tokenList }
          return map
    }, {}) as ChainTokenMap
    return map
}

export function combineMaps(map1: ChainTokenMap, map2: ChainTokenMap): ChainTokenMap {
    const chainIds = Object.keys(
      Object.keys(map1)
        .concat(Object.keys(map2))
        .reduce<{ [chainId: string]: true }>((memo, value) => {
          memo[value] = true
          return memo
        }, {})
    ).map((id) => parseInt(id))
  
    return chainIds.reduce<Mutable<ChainTokenMap>>((memo, chainId) => {
      memo[chainId] = {
        ...map2[chainId],
        // map1 takes precedence
        ...map1[chainId],
      }
      return memo
    }, {}) as ChainTokenMap
  }


function useFetchResultToChainTokenMap(fetchedTokensLists: FetchResult[]) {
    return useMemo(() => {
        return fetchedTokensLists
            .filter(result => result.data)
            .map(result => result.data)
            .reduce((acc, curr) => {
                try {
                    return combineMaps(acc, tokensToChainTokenMap(curr))
                } catch(err) {
                    console.error('Could not show token list due to error', err)
                    return acc
                }
            }, {}) as ChainTokenMap
    }, [fetchedTokensLists])
}

function useTokensFromMap(tokenMap: ChainTokenMap, chainId: number): { [address: string]: Token } {
    const mapWithoutUrls = Object.keys(tokenMap[chainId] ?? {}).reduce<{ [address: string]: Token }>(
        (newMap, address) => {
          newMap[address] = tokenMap[chainId][address].token
          return newMap
    },{})

    return mapWithoutUrls
}


export default function BackTesting({ chainId = 1 }: { chainId?: number} ) {
    
    const [dates, setDates] = useState<DatesInterface>(
        {
            startDate: subMonths(new Date(), 1),
            endDate: subDays(new Date(), 1),
            sliderDate: null,
            today: new Date()
        }
    )
    
    const [currencyA, setCurrencyA] = useState<Currency>(useNativeCurrency(chainId));
    const [currencyB, setCurrencyB] = useState<Currency>();

    const [focusedToken, setFocusedToken] = useState<FocusedToken>(FocusedToken.QUOTE)

    const [independentInputAmount, setIndependentInputAmount] = useState<string>('0.0')
    const [dependentInputAmount, setDependentInputAmount] = useState<string>('0.0')
 

    const [lockedBase, setLockedBase] = useState<boolean>(false)
    const [lockedQuote, setLockedQuote] = useState<boolean>(false)

    const [chosenTicks, setChosenTicks] = useState<[number, number] | null>(null)

    const [feeTier, setFeeTier] = useState<FeeAmount>(FeeAmount.LOW)
    
    const [showDialog, setShowDialog] = useState(false)


    const activeListUrls =  useActiveListUrls(chainId)

    const fetchedTokensLists = useFetchMultiple(activeListUrls)

    const tokenMap = useFetchResultToChainTokenMap(fetchedTokensLists)

    const tokensFromMap = useTokensFromMap(tokenMap, chainId)

    const nativeCurrency = useNativeCurrency(chainId)

    const { data: topTokensData, loading: topTokensLoading, error: topTokensError } = useFetchTopTokens(chainId)

    const topTokensIds: string[] | undefined = useGetAddress(topTokensData)

    const sorted = useMemo(() => {
        if(!currencyA || !currencyB) return
        return currencyA.wrapped.sortsBefore(currencyB.wrapped)
    }, [currencyA, currencyB])

    const filteredTopTokens = useMemo(() => {
        return Object.values(Object.keys(tokensFromMap)
        .filter((key: string) => topTokensIds?.includes(key))
        .reduce((acc: { [key: string]: ( Token | NativeCurrency )}, curr: string ) => {
            if(curr === getAddress(nativeCurrency.wrapped.address)) {
                acc[curr] = nativeCurrency
            } else {
                acc[curr] = tokensFromMap[curr]
            }
            return acc
        }, {})
        )
    }, [nativeCurrency, tokensFromMap, topTokensIds])

    const tokens = useMemo(() => [...Object.values(tokensFromMap), nativeCurrency], 
        [nativeCurrency, tokensFromMap]
    )

    // useEffect(() => {
    //     if(nativeCurrency) {
    //         setCurrencyA(nativeCurrency)
    //     }
    // }, [nativeCurrency])

    const handleCurrencySelect = useCallback((token: (Token | NativeCurrency)) => {
        if (focusedToken === FocusedToken.BASE){
            if(currencyB?.wrapped.equals(token.wrapped)) {
                setCurrencyA(token)
                setCurrencyB(undefined)
            } else setCurrencyA(token)
        } else {
            if(!currencyA?.wrapped.equals(token.wrapped)) setCurrencyB(token)
            else {
                setCurrencyA(token)
                setCurrencyB(undefined)
            }
        }
    }, [currencyA, currencyB, focusedToken])
    
    const [tokenA, tokenB] = useMemo(() => {
        if(currencyA && currencyB) {
            return !sorted ? [currencyB.wrapped, currencyA.wrapped] : [currencyA.wrapped, currencyB.wrapped]
        }
        return [undefined, undefined]
    }, [currencyA, sorted, currencyB])


    const address = useMemo(() => {
        if(!tokenA || !tokenB) return
        return computePoolAddress({ factoryAddress, tokenA, tokenB, fee: feeTier }).toLocaleLowerCase()
    }, [feeTier, tokenA, tokenB])


    const { data: blocks, loading: blocksLoading, error: blocksError } = useGetBlockNumbers(dates, chainId)
    const { data: allBlocks, loading: allblocksLoading, error: allBlocksError } = useGetSingleBlockNumber(dates.sliderDate, blocks, chainId)

    const { 
        data: currentPoolData, 
        loading: currentPoolLoading, 
        error: currentPoolError 
    } = useFetchPoolData({ feeTier, tokenA, tokenB, chainId })

    const { 
        data: poolInData, 
        loading: poolInLoading, 
        error: poolInError 
    } = useFetchPoolDataWithBlock({ feeTier, tokenA, tokenB, blockNumber: allBlocks.IN?.number })
    const { 
        data: poolOutData, 
        loading: poolOutLoading, 
        error: poolOutError 
    } = useFetchPoolDataWithBlock({ feeTier, tokenA, tokenB, blockNumber: allBlocks.OUT?.number })

    const { 
        data: poolSliderData, 
        loading: poolSliderLoading, 
        error: poolSliderError  
    } = useFetchPoolDataWithBlock({ feeTier, tokenA, tokenB, blockNumber: allBlocks.SLIDER?.number })

    const { formattedPoolHourData: poolHourData, loading: poolHourDataLoading, error: poolHourDataError } = useFetchPoolHourData(dates, chainId, address)


    const { 
        liquidity: liquidityCurrent, 
        sqrtPrice: sqrtPriceCurrent,
        tick: tickCurrent 
    } = currentPoolData || {}

    const { 
        liquidity: liquidityLower, 
        sqrtPrice: sqrtPriceLower, 
        tick: tickLower 
    } = poolInData || {}

    const { 
        liquidity: liquidityUpper, 
        sqrtPrice: sqrtPriceUpper, 
        tick: tickUpper 
    } = poolOutData || {}

    const { 
        liquidity: liquiditySlider, 
        sqrtPrice: sqrtPriceSlider, 
        tick: tickSlider
    } = poolSliderData || {}


    const poolCurrent = useMemo(() => {
        if(!tokenA || !tokenB || !sqrtPriceCurrent || !liquidityCurrent || !tickCurrent) return
        
        return new Pool(tokenA, tokenB, feeTier, sqrtPriceCurrent, liquidityCurrent, parseInt(tickCurrent))
    }, [tokenA, tokenB, sqrtPriceCurrent, feeTier, liquidityCurrent, tickCurrent])

    const poolIn = useMemo(() => {
        if(!tokenA || !tokenB || !sqrtPriceLower || !liquidityLower || !tickLower) return
        
        return new Pool(tokenA, tokenB, feeTier, sqrtPriceLower, liquidityLower, parseInt(tickLower))
    }, [tokenA, tokenB, feeTier, sqrtPriceLower, liquidityLower, tickLower])

    const poolOut = useMemo(() => {
        if(!tokenA || !tokenB || !sqrtPriceUpper || !liquidityUpper || !tickUpper) return
        
        return new Pool(tokenA, tokenB, feeTier, sqrtPriceUpper, liquidityUpper, parseInt(tickUpper))
    }, [tokenA, tokenB, sqrtPriceUpper, liquidityUpper, tickUpper, feeTier])

    const poolSlider = useMemo(() => {
        if(!tokenA || !tokenB || !sqrtPriceSlider || !liquiditySlider || !tickSlider) return
        
        return new Pool(tokenA, tokenB, feeTier, sqrtPriceSlider, liquiditySlider, parseInt(tickSlider))
    }, [tokenA, tokenB, sqrtPriceSlider, liquiditySlider, tickSlider, feeTier])


    /* This effect synchronizes the the selected range with the pool initial tick in case of single-asset
        deposits only, or both asset depositis */
    useEffect(() => {
        if(!chosenTicks || !poolIn) return

        if(sorted) {
            if(chosenTicks[0] > poolIn.tickCurrent) {
                setLockedQuote(true)
            } else if(chosenTicks[1] < poolIn.tickCurrent) {
                setLockedBase(true)
            } else {
                setLockedBase(false)
                setLockedQuote(false)
            }
        } else {
            if(chosenTicks[0] > poolIn.tickCurrent) {
                setLockedBase(true) 
            } else if(chosenTicks[1] < poolIn.tickCurrent) {
                setLockedQuote(true)
            } else {
                setLockedBase(false)
                setLockedQuote(false)
            }
        }
    }, [sorted, chosenTicks, poolIn])


    const positionIn = useMemo(() => {
        if(!chosenTicks || !chosenTicks.length || !poolIn) return

        if(!parseFloat(independentInputAmount)) {
            setDependentInputAmount('0.0')
            return
        }

        let position

        let ticks = (chosenTicks[0] > chosenTicks[1]) ? [...chosenTicks].reverse() : chosenTicks

        if(focusedToken === FocusedToken.BASE) {
            if(sorted) {
                if(ticks[0] > poolIn.tickCurrent) {
                    position = Position.fromAmount0({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount0: parseUnits(independentInputAmount, poolIn.token0.decimals).toString(),
                        useFullPrecision: true
                    })
                    setDependentInputAmount('0.00')
                } else if(ticks[1] < poolIn.tickCurrent) {
                    position = Position.fromAmount1({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount1: parseUnits(independentInputAmount, poolIn.token1.decimals).toString(),
                    })
                    setDependentInputAmount('0.00')
                } else {
                    position = Position.fromAmount0({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount0: parseUnits(independentInputAmount, poolIn.token0.decimals).toString(),
                        useFullPrecision: true
                    })
                    setDependentInputAmount(position.amount0.toFixed(4))
                }
            } else {
                if(ticks[0] > poolIn.tickCurrent) {
                    position = Position.fromAmount1({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount1: parseUnits(independentInputAmount, poolIn.token1.decimals).toString()
                    })
                    setDependentInputAmount('0.00')
                } else if(ticks[1] < poolIn.tickCurrent) {
                    
                    position = Position.fromAmount0({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount0: parseUnits(independentInputAmount, poolIn.token0.decimals).toString(),
                        useFullPrecision: true
                    })
                    setDependentInputAmount('0.00')
                } else {
                    position = Position.fromAmount1({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount1: parseUnits(independentInputAmount, poolIn.token1.decimals).toString()
                    })
                    setDependentInputAmount(position.amount0.toFixed(4))
                }
            }
        } else {
            if(sorted) {
                if(ticks[0] > poolIn.tickCurrent) {
                    position = Position.fromAmount0({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount0: parseUnits(independentInputAmount, poolIn.token0.decimals).toString(),
                        useFullPrecision: true
                    })
                    setDependentInputAmount('0.0')
                } else if(ticks[1] < poolIn.tickCurrent) {
                    position = Position.fromAmount1({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount1: parseUnits(independentInputAmount, poolIn.token1.decimals).toString(),
                    })
                    setDependentInputAmount('0.0')
                } else {
                    position = Position.fromAmount1({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount1: parseUnits(independentInputAmount, poolIn.token1.decimals).toString(),
                    })
                    setDependentInputAmount(position.amount0.toFixed(4))
                }
            } else {
                if(ticks[0] > poolIn.tickCurrent) {
                    position = Position.fromAmount0({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount0: parseUnits(independentInputAmount, poolIn.token0.decimals).toString(),
                        useFullPrecision: true
                    })
                    setDependentInputAmount('0.0')
                } else if(ticks[1] < poolIn.tickCurrent) {
                    position = Position.fromAmount1({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount1: parseUnits(independentInputAmount, poolIn.token1.decimals).toString(),
                    })
                    setDependentInputAmount('0.0')
                } else {
                    position = Position.fromAmount0({
                        pool: poolIn,
                        tickLower: ticks[0],
                        tickUpper: ticks[1],
                        amount0: parseUnits(independentInputAmount, poolIn.token0.decimals).toString(),
                        useFullPrecision: true
                    })
                    setDependentInputAmount(position.amount1.toFixed(4))
                }
            }
        }

        return position

    }, [chosenTicks, poolIn, independentInputAmount, focusedToken, sorted])


    const positionOut = useMemo(() => {
        if(!chosenTicks || !chosenTicks.length || !poolOut  || !positionIn) return 

        let ticks = (chosenTicks[0] > chosenTicks[1]) ? [...chosenTicks].reverse() : chosenTicks

        return new Position({
            pool: poolOut,
            tickLower: ticks[0],
            tickUpper: ticks[1],
            liquidity: positionIn.liquidity.toString()
        })

    }, [chosenTicks, poolOut, positionIn])

    const positionSlider = useMemo(() => {
        if(!chosenTicks || !chosenTicks.length || !poolSlider  || !positionIn) return 

        let ticks = (chosenTicks[0] > chosenTicks[1]) ? [...chosenTicks].reverse() : chosenTicks

        return new Position({
            pool: poolSlider,
            tickLower: ticks[0],
            tickUpper: ticks[1],
            liquidity: positionIn.liquidity.toString()
        })

    }, [chosenTicks, poolSlider, positionIn])

    const positions: { [key in Positions]?: Position } = useMemo(
        () => ({
          [Positions.IN]: positionIn, 
          [Positions.OUT]: positionOut,
          [Positions.SLIDER]: positionSlider
        }),
        [positionIn, positionOut, positionSlider]
    )

    const percentage = useMemo(() => {
        if(!chosenTicks || !poolHourData) return

        const count = poolHourData.filter((item) => {
            if(chosenTicks[0] < item.tick && item.tick < chosenTicks[1]) {
                return true
            }
            return false
        }).length

        return (new Percent(count, poolHourData.length)).toFixed(2)

    }, [chosenTicks, poolHourData])

    return (
        <BackTestingWrapper>
            <StyledWrapper>
                { showDialog ?
                    <DefiMathModal
                        isOpen={showDialog}
                        onSelect={handleCurrencySelect}
                        onDismiss={() => setShowDialog(false)}
                        data={tokens}
                        chainId={chainId}
                    /> : null
                }
                <AddLiquidity 
                    currencyA={currencyA} 
                    currencyB={currencyB}
                    feeAmount={feeTier}
                    onFeeAmountChange={setFeeTier}
                    setCurrencyA={setCurrencyA} 
                    setCurrencyB={setCurrencyB}
                    setOpenModal={setShowDialog}
                    setFocusedToken={setFocusedToken}
                    range={dates}
                    handleSetRange={setDates}
                    onUserInput={setIndependentInputAmount}
                    independentInputAmount={independentInputAmount}
                    dependentInputAmount={dependentInputAmount}
                    currentFocus={focusedToken}
                    lockedBase={lockedBase}
                    lockedQuote={lockedQuote}
                    timeInRange={percentage}
                />
                <ChartsWrapper> 
                    <PriceChartWrapper
                        baseToken={currencyA}
                        quoteToken={currencyB}
                        onChosenTicks={setChosenTicks}
                        feeTier={feeTier}
                        ticks={chosenTicks}
                        pool={poolCurrent}
                        currentPoolError={currentPoolError}
                        currentPoolLoading={currentPoolLoading}
                        poolHourData={poolHourData}
                        isLoading={poolHourDataLoading}
                        isError={poolHourDataError}
                        sorted={sorted}
                    />
                    <LiquidityChartWrapper
                        poolCurrent={poolCurrent}
                        poolIn={poolIn}
                        poolOut={poolOut}
                        poolAddress={address}
                        ticks={chosenTicks}
                        onChosenTicks={setChosenTicks}
                        feeTier={feeTier}
                        dates={dates}
                        blocks={allBlocks}
                        sorted={sorted}
                        priceChartLoading={poolHourDataLoading}
                    />
                </ChartsWrapper>

                <PositionResults
                    currencyA={currencyA}
                    currencyB={currencyB}
                    positions={positions}
                    blocks={allBlocks}
                    poolHourData={poolHourData}
                    isLoading={poolHourDataLoading}
                    isError={poolHourDataError}
                    chainId={chainId}
                    onNewSliderDate={setDates}
                    sliderDate={dates.sliderDate}
                    sorted={sorted}
                ></PositionResults>
            </StyledWrapper>
        </BackTestingWrapper>
    )
}

