import { css } from 'styled-components/macro'
import { Text } from 'rebass/styled-components'
import styled from 'styled-components/macro'

export const MEDIA_WIDTHS = {
    upToExtraSmall: 500,
    upToSmall: 720,
    upToMedium: 960,
    upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
    (accumulator, size) => {
      ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
        @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
          ${css(a, b, c)}
        }
      `
      return accumulator
    }, {}
  ) as any


export const theme = {
    green: 'rgb(0,250,191)',
    lightBlue: 'rgb(1,179,236)',
    blue: 'rgb(8, 20, 84)',
    darkBlue: 'rgb(0,0,64)',
    doubleDarkBlue: 'rgb(8,4,36)',
    pink: 'rgb(252, 7, 125)',
    white: 'rgb(255,255,255)',
    gray: 'rgb(195, 197, 203)',
    fontFamily: 'Source Sans Pro, sans-serif',
    gradient: 'linear-gradient(180deg, rgba(127,33,232,1) 0%, rgba(33,126,233,1) 100%)',
    mediaWidth: mediaWidthTemplates
}

export interface DefiMathTheme {
  green: string
  lightBlue: string
  blue: string
  darkBlue: string
  doubleDarkBlue: string
  pink: string
  white: string
  gray: string
  fontFamily: string
  gradient: string
  mediaWidth: typeof mediaWidthTemplates
}
