import { useEffect, useState, ReactNode, useCallback, RefObject, MutableRefObject, useRef, MouseEvent, TouchEvent } from 'react'
import { select, zoom, zoomTransform, ZoomBehavior, ZoomTransform, zoomIdentity, Selection, pointer } from 'd3'
import SVGSurface from '../SVGSurface'
import styled from 'styled-components/macro'
import { ZoomIn, ZoomOut }from 'react-feather'
import { RotateCcw } from 'react-feather'
import Row, { AutoRow } from '../Row'

const ButtonsWrapper = styled.div`
    border-radius: 20px;
    background-color: ${({ theme }) => theme.blue};

`

const Button = styled.button`
    font-weight: 500;
    text-align: center;
    cursor: pointer; 
    border-radius: 20px;
    color: ${({ theme }) => theme.white };
    text-align: center;
    outline: none;
    border: 1px solid transparent;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 1;
    background-color: ${({ theme }) => theme.blue };
    width: 32px;
    height: 32px;
    padding: 4px;
    &:disabled {
        cursor: auto;
        pointer-events: none;
    }
    &:hover {
        opacity: 10%;
    }
`


export default function Zoom({ 
    svg, 
    setZoom, 
    x1, 
    y1, 
    nullifyPointCords 
}: {
    svg: SVGRectElement | null
    setZoom: (transform: ZoomTransform) => void
    x1: number;
    y1: number;
    nullifyPointCords?: (v: null) => void
} ) {
    const zoomRef = useRef<ZoomBehavior<Element, unknown>>()
    const selection = useRef<Selection<Element, unknown, null, unknown>>()

    const zoomIn = useCallback((e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
        if(!svg || !zoomRef.current || !selection.current) return
       
        const node = selection.current.node()

        if(!node) return 

        selection.current.call(zoomRef.current.scaleBy, 1.1)
    }, [svg])

    const zoomOut = useCallback((e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
        if(!svg || !zoomRef.current || !selection.current) return
        
        const node = selection.current.node()

        if(!node) return 

        selection.current.call(zoomRef.current.scaleBy, 0.9)
    }, [svg])

    const restartZoom = () => {
        if(!svg || !zoomRef.current || !selection.current) return
        selection.current.call(zoomRef.current.transform, zoomIdentity)
    }


    useEffect(() => {
        if(!svg) return 

        zoomRef.current = zoom<Element, unknown>()
            .extent([[0, 0], [x1, y1]])
            .scaleExtent([1, 2])
            .translateExtent([[0, 0], [x1, y1]])
            .on('zoom', ({transform}) => {
                setZoom(transform)
            })
            .on('start', () => {
                if(nullifyPointCords) nullifyPointCords(null)
            })
                
        selection.current = select<Element, unknown>(svg)
        selection.current.call(zoomRef.current)


    }, [nullifyPointCords, setZoom, svg, x1, y1])



    return(
        <AutoRow width={'auto'} gap={'2px'}>
            <ButtonsWrapper>
                <Button onClick={zoomIn}>
                    <ZoomIn size={16} />
                </Button>
            </ButtonsWrapper>
            <ButtonsWrapper>
                <Button onClick={zoomOut}>
                    <ZoomOut size={16}/>
                </Button>
            </ButtonsWrapper>
            <ButtonsWrapper>
                <Button onClick={restartZoom}>
                    <RotateCcw size={16}/>
                </Button>
            </ButtonsWrapper>
        </AutoRow>
    )
}
