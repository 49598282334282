import ethereumLogoUrl from '../assets/images/ethereum-logo.png'
import arbitrumLogoUrl from '../assets/svg/arbitrum_logo.svg'
import optimismLogoUrl from '../assets/svg/optimism_logo.svg'
import polygonMaticLogo from '../assets/svg/polygon-matic-logo.svg'

export enum SupportedChainId {
    MAINNET = 1,
    POLYGON = 137,
    ARBITRUM_ONE = 42161,
    OPTIMISM = 10
}

export const ChainIdToNames = {
    [SupportedChainId.MAINNET]: 'Mainnet',
    [SupportedChainId.POLYGON]: 'Polygon',
    [SupportedChainId.ARBITRUM_ONE]: 'Arbitrium',
    [SupportedChainId.OPTIMISM]: 'Opstimism'
}

export enum NetworkType {
    L1,
    L2,
}
  

interface BaseChainInfo {
    readonly networkType: NetworkType
    readonly blockWaitMsBeforeWarning?: number
    readonly docs: string
    readonly bridge?: string
    readonly explorer: string
    readonly infoLink: string
    readonly logoUrl: string
    readonly label: string
    readonly helpCenterUrl?: string
    readonly nativeCurrency: {
      name: string 
      symbol: string 
      decimals: number 
    }
}

export type ChainInfoMap  = { readonly [chainId: number]: BaseChainInfo }

export const CHAIN_INFO: ChainInfoMap = {
    [SupportedChainId.MAINNET]: {
      networkType: NetworkType.L1,
      docs: 'https://docs.uniswap.org/',
      explorer: 'https://etherscan.io/',
      infoLink: 'https://info.uniswap.org/#/',
      label: 'Ethereum',
      logoUrl: ethereumLogoUrl,
      nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    },
    [SupportedChainId.OPTIMISM]: {
      networkType: NetworkType.L2,
      bridge: 'https://gateway.optimism.io/?chainId=1',
      docs: 'https://optimism.io/',
      explorer: 'https://optimistic.etherscan.io/',
      infoLink: 'https://info.uniswap.org/#/optimism/',
      label: 'Optimism',
      logoUrl: optimismLogoUrl,
      helpCenterUrl: 'https://help.uniswap.org/en/collections/3137778-uniswap-on-optimistic-ethereum-oξ',
      nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    },
    [SupportedChainId.ARBITRUM_ONE]: {
      networkType: NetworkType.L2,
      bridge: 'https://bridge.arbitrum.io/',
      docs: 'https://offchainlabs.com/',
      explorer: 'https://arbiscan.io/',
      infoLink: 'https://info.uniswap.org/#/arbitrum',
      label: 'Arbitrum',
      logoUrl: arbitrumLogoUrl,
      helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
      nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    },
    [SupportedChainId.POLYGON]: {
      networkType: NetworkType.L1,
      bridge: 'https://wallet.polygon.technology/bridge',
      docs: 'https://polygon.io/',
      explorer: 'https://polygonscan.com/',
      infoLink: 'https://info.uniswap.org/#/polygon/',
      label: 'Polygon',
      logoUrl: polygonMaticLogo,
      nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
    }
  }