import { CurrencyAmount, Currency, Token, Price, Fraction } from '@uniswap/sdk-core'
import { WrappedTokenInfo } from '../utils/WrappedTokenInfo'
import { useMemo, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { USDC } from '../constants/tokens'
import JSBI from 'jsbi'
import { parseUnits } from '@ethersproject/units'
import useGetClients from './useGetClients'

const TOKENPRICEQUERY = gql`
    query TokensPrice($address: ID!, $blockNumber: Int!) {
        token(id: $address, block: { number: $blockNumber }) {
            derivedETH
        }
    }
`

const ETHPrice = gql`
    query ETHPrice($blockNumber: Int!) {
        bundles(first:1, block: { number: $blockNumber }) {
            ethPriceUSD
        }
    }
`


export function useTheGraphEthPrice(blockNumber: number | undefined, chainId: number) {
    const { client } = useGetClients(chainId)
    const { data } = useQuery(ETHPrice, {
        client,
        skip: !blockNumber,
        variables: {
            blockNumber: Number(blockNumber)
        }
    })

    return useMemo(() => {
        if(!data) return undefined
        return data.bundles.map((bundle: { ethPriceUSD: string }) => parseFloat(bundle.ethPriceUSD))[0]
    }, [data])
}

export default function useTheGraphUSDCPrice({
    currencyAmount, 
    block, 
    chainId
}: {
    currencyAmount: CurrencyAmount<Token> | undefined
    block: number | undefined
    chainId: number
}) {
    const { client } = useGetClients(chainId)

    const ethPrice = useTheGraphEthPrice(block, chainId)
    
    const { data }  = useQuery(TOKENPRICEQUERY, {
        client,
        skip: !block || !currencyAmount,
        variables: {
            address: currencyAmount?.currency.address.toLowerCase(),
            blockNumber: Number(block)
        }
    })

    const derivedEth = useMemo(() => {
        if(!data) return undefined
        return parseFloat(data.token.derivedETH)
    }, [data])

    return useMemo(() => {
        const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96))
        const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2))

        if(!ethPrice || !derivedEth || !currencyAmount) return undefined
        if(currencyAmount.currency.equals(USDC)) return new Price(USDC, USDC, Q192, Q192)
    

        const quoteAmount = parseUnits((ethPrice * derivedEth).toFixed(6), USDC.decimals).toString()

        const baseAmount = parseUnits('1', currencyAmount.currency.decimals).toString()

        // Both options seem to be valid
        // const sqrtRatioX96 = encodeSqrtRatioX96(quoteAmount, baseAmount)
        // const ratio = JSBI.multiply(sqrtRatioX96, sqrtRatioX96)
        return new Price(currencyAmount.currency, USDC, baseAmount, quoteAmount)

    }, [currencyAmount, derivedEth, ethPrice])
}
