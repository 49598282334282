//LAYOUT
import { ColumnCenter } from '../Column'
import Row, { AutoRow } from '../Row'
import { TextWrapper, TokenIcon } from '../../theme/components'
import styled from 'styled-components/macro'

//Images
import { RotateCcw } from 'react-feather'

//React
import { Dispatch, SetStateAction, useState } from 'react'

//UNISWAP
import { Currency } from '@uniswap/sdk-core'
import { WrappedTokenInfo } from '../../utils/WrappedTokenInfo'
import { FeeAmount } from '@uniswap/v3-sdk'

//Internals
import ethereumLogo from '../../assets/ethereumLogo.png'
import { FocusedToken } from '../../constants/enums'
import { FeeTierToPercentage } from '../../constants/v3'

//Components
import DatesRange from '../../components/DatesRangePicker/DatesRange'
import { DropDownButton, BaseButton } from '../../components/Buttons'

import TimeInRangeOptions from '../TimeInRangeOptions'

//Interfaces
import { DatesInterface } from '../../interfaces'

//Hooks
import CurrencyInputPanel from '../CurrencyInputPanel'


const AddLiquidityWrapper = styled.div`
    border-radius: 16px;   
    background-color: ${({ theme }) => theme.darkBlue };
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin: 10px 30px;
        padding: 10px 34px;
    `}
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 10px 10px;
        padding: 10px 8px;
    `}
`
const StyledButtonsContainer = styled(Row)`
    gap: 10px;
    justify-content: space-between;
`

export default function AddLiquidity({ 
    currencyA, 
    currencyB,
    feeAmount,
    onFeeAmountChange,
    setCurrencyA, 
    setCurrencyB, 
    setOpenModal, 
    currentFocus, 
    setFocusedToken, 
    range, 
    handleSetRange, 
    onUserInput, 
    independentInputAmount,
    dependentInputAmount,
    lockedBase,
    lockedQuote,
    timeInRange
}: {
    currencyA?: Currency
    currencyB?: Currency
    feeAmount: FeeAmount
    onFeeAmountChange: Dispatch<SetStateAction<FeeAmount>>
    setCurrencyA: Dispatch<SetStateAction<Currency>>
    setCurrencyB: Dispatch<SetStateAction<Currency | undefined>>
    setOpenModal: Dispatch<SetStateAction<boolean>>
    setFocusedToken: Dispatch<SetStateAction<FocusedToken>>
    range: DatesInterface
    handleSetRange: Dispatch<SetStateAction<DatesInterface>>
    onUserInput: Dispatch<SetStateAction<string>>
    currentFocus: FocusedToken
    independentInputAmount: string
    dependentInputAmount: string
    lockedBase: boolean
    lockedQuote: boolean
    timeInRange: string | undefined
} ) {
    const [renderCalendar, setRenderCalendar] = useState(false)

    return (
        <AddLiquidityWrapper>
            <ColumnCenter>
                <TextWrapper fontSize={3} paddingTop={1}>
                    Add Liquidity
                </TextWrapper>
                <AutoRow gap={'6px'}>
                        <AutoRow>
                            <Row marginY={1}>
                                <TextWrapper fontSize={2}>
                                    Select Tokens
                                </TextWrapper>
                            </Row>
                            <StyledButtonsContainer>
                                <DropDownButton onClick={() => {
                                    setOpenModal(true)
                                    setFocusedToken(FocusedToken.BASE)
                                }}>
                                    <TokenIcon src={ currencyA instanceof WrappedTokenInfo ? currencyA.tokenInfo.logoURI : ethereumLogo }/>
                                    <TextWrapper fontSize={3}>
                                        { currencyA ? currencyA.isToken ? currencyA.symbol : 'ETH' : null }
                                    </TextWrapper>
                                </DropDownButton>
                                <DropDownButton onClick={() => {
                                    setOpenModal(true)
                                    setFocusedToken(FocusedToken.QUOTE)
                                }}>
                                    { currencyB ? <TokenIcon src={currencyB instanceof WrappedTokenInfo ? currencyB.tokenInfo.logoURI : ethereumLogo}/> : null }
                                    { currencyB ? (
                                        <TextWrapper fontSize={3}>
                                            { currencyB ? currencyB.wrapped.symbol : null }
                                        </TextWrapper>
                                    ): <TextWrapper>Select Token</TextWrapper>}
                                </DropDownButton>
                                <DropDownButton onClick={() => {
                                    if(!currencyA || !currencyB) return
                                    setCurrencyA(currencyB)
                                    setCurrencyB(currencyA)
                                }}>
                                    <RotateCcw size={20}></RotateCcw>
                                </DropDownButton>
                            </StyledButtonsContainer>
                        </AutoRow>
                        <AutoRow>
                            <Row marginY={1}>
                                <TextWrapper fontSize={2}>
                                    Select Fee Tier
                                </TextWrapper>
                            </Row>
                            <StyledButtonsContainer> 
                                <BaseButton 
                                    onClick={() => onFeeAmountChange(FeeAmount.LOWEST)} 
                                    active={feeAmount === FeeAmount.LOWEST}><TextWrapper>{FeeTierToPercentage[FeeAmount.LOWEST]}%</TextWrapper></BaseButton>
                                <BaseButton 
                                    onClick={() => onFeeAmountChange(FeeAmount.LOW)}  
                                    active={feeAmount === FeeAmount.LOW}><TextWrapper>{FeeTierToPercentage[FeeAmount.LOW]}%</TextWrapper></BaseButton>
                                <BaseButton 
                                    onClick={() => onFeeAmountChange(FeeAmount.MEDIUM)}  
                                    active={feeAmount === FeeAmount.MEDIUM}><TextWrapper>{FeeTierToPercentage[FeeAmount.MEDIUM]}%</TextWrapper></BaseButton>
                                <BaseButton 
                                    onClick={() => onFeeAmountChange(FeeAmount.HIGH)}  
                                    active={feeAmount === FeeAmount.HIGH}><TextWrapper>{FeeTierToPercentage[FeeAmount.HIGH]}%</TextWrapper></BaseButton>
                            </StyledButtonsContainer>
                        </AutoRow>
                        <AutoRow>
                            <Row marginY={1}>   
                                <TextWrapper fontSize={2}>
                                    Select dates range
                                </TextWrapper>
                            </Row>
                            <Row justify={'center'}>
                                <DatesRange 
                                    range={range}
                                    onRangeChange={(item: DatesInterface) => handleSetRange(item)}
                                    onHandleRenderCalendar={setRenderCalendar}
                                    renderCalendar={renderCalendar}
                                />
                            </Row>
                        </AutoRow>
                        { !renderCalendar ?  (
                            <AutoRow>
                                <Row marginY={1}>
                                    <TextWrapper fontSize={2}>
                                            Deposit Amounts
                                    </TextWrapper>
                                </Row>
                                <Row>
                                    <CurrencyInputPanel 
                                        locked={lockedBase}
                                        currency={currencyA}
                                        currentFocus={currentFocus}
                                        focusFor={FocusedToken.BASE}
                                        handleSetDependency={setFocusedToken}
                                        onUserInput={onUserInput}
                                        value={currentFocus === FocusedToken.BASE ? independentInputAmount : dependentInputAmount}
                                        date={range.startDate}
                                    />
                                </Row>
                                <Row marginY={1}>
                                    <CurrencyInputPanel 
                                        locked={lockedQuote}
                                        currency={currencyB}
                                        currentFocus={currentFocus}
                                        focusFor={FocusedToken.QUOTE}
                                        handleSetDependency={setFocusedToken}
                                        onUserInput={onUserInput}
                                        value={currentFocus === FocusedToken.BASE ? dependentInputAmount : independentInputAmount}
                                        date={range.startDate}
                                    />
                                </Row>
                            </AutoRow>

                        ) : null }
                    <TimeInRangeOptions onUserInput={() => ({})} timeInRange={timeInRange}/>
                </AutoRow>
            </ColumnCenter>
        </AddLiquidityWrapper>
    )
}




