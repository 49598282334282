
//COMPONENTS
import { TextWrapper } from '../../theme/components'
import Row from '../Row'
import { AutoColumn } from '../Column'

//LIBS
import { useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

//UNISWAPV3
import { Position } from '@uniswap/v3-sdk'

//UNICORE
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'

//HOOKS
import useTheGraphUSDCPrice from '../../hooks/useTheGraphUSDCPrice'

//REACT
import { useMemo } from 'react'

export default function LiquidityResult({ 
    out, 
    currencyA, 
    currencyB, 
    position, 
    block, 
    onNewFiatValue,
    chainId,
    sorted
} : {
    out: boolean
    currencyA: Currency
    currencyB: Currency | undefined
    position: Position | undefined
    block: number | undefined
    onNewFiatValue: (value: CurrencyAmount<Token> | null, out: boolean) => void
    chainId: number
    sorted: boolean | undefined
} ) {
    const theme = useContext(ThemeContext)

    const priceAmount0USDC = useTheGraphUSDCPrice({ currencyAmount: position?.amount0, block, chainId })
    const priceAmount1USDC = useTheGraphUSDCPrice({ currencyAmount: position?.amount1, block, chainId })

    const fiatValueOfLiquidity = useMemo(() => {
        if (!priceAmount0USDC || !priceAmount1USDC || !position)  return null

        return priceAmount0USDC.quote(position.amount0).add(priceAmount1USDC.quote(position.amount1))
    }, [position, priceAmount0USDC, priceAmount1USDC])

    useEffect(() => {
    if(!fiatValueOfLiquidity) return

    onNewFiatValue(fiatValueOfLiquidity, out)
    }, [fiatValueOfLiquidity, onNewFiatValue, out])

    return (
        <Row paddingX={4} paddingY={1} backgroundColor={theme.blue} borderRadius={'20px'}>
            <AutoColumn>
                <TextWrapper fontSize={1} color={theme.lightBlue}>
                    LIQUIDITY {out ? 'OUT' : 'IN'}
                </TextWrapper>
                <TextWrapper fontSize={1}>
                    Liquidity USD: ${fiatValueOfLiquidity ? fiatValueOfLiquidity.toSignificant(4) : '0.0'}
                </TextWrapper>
                <TextWrapper fontSize={1}>
                    {currencyA.symbol}: {position ? sorted ? position.amount0.toSignificant(6) : position.amount1.toSignificant(6) : '0.0'}
                </TextWrapper>
                <TextWrapper fontSize={1}>
                    {currencyB ? currencyB.symbol + ': ': 'Select quote token'} {position ? sorted ? position.amount1.toSignificant(6) : position.amount0.toSignificant(6) : currencyB ? '0.0' : ''}
                </TextWrapper>
            </AutoColumn>
        </Row>
    )
}
