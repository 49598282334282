import React, { useState } from 'react';
import styled from 'styled-components/macro'
import Header from './components/Header/index'
import BackTesting from './pages/BackTesting/index'
import { SupportedChainId } from './constants/chains'

const AppWrapper = styled.section`
  width: 100%
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

function App() {
  const [activeChainId, setActiveChainId] = useState<SupportedChainId>(SupportedChainId.MAINNET)

  return (
      <AppWrapper>
        <HeaderWrapper>
          <Header 
            activeChainId={activeChainId} 
            onActiveChainIdChange={setActiveChainId}
          ></Header>
        </HeaderWrapper>
        <BodyWrapper>
          <BackTesting chainId={activeChainId}></BackTesting>
        </BodyWrapper>
      </AppWrapper>
  );
}

export default App;
