import styled from 'styled-components/macro'
import { InputHTMLAttributes } from 'react'

export const StyledInput = styled.input<{ border?: string; align?: string; background?: string }>`
    background-color: ${({ theme, background }) => background ?? theme.blue };
    color: ${({ theme }) => theme.white};
    width: 100%;
    font-weight: 500;
    outline: none;
    border: none;  
    
    font-size: 24px;
    text-align:  ${({ align }) => align ? align : 'left'};
    border-radius: 16px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: textfield;

    [type='number'] {
        -moz-appearance: textfield;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  
    ::placeholder {
      color: ${({ theme }) => theme.gray};
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
    `};

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        font-size: 12px;
    `};
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  border-radius: 20px;
  color: ${({ theme }) => theme.white };
  border-style: solid;
  border: 1px solid ${({ theme }) => theme.blue };
  -webkit-appearance: none;

  font-size: 18px;

  ::placeholder {
    color: ${({ theme }) => theme.gray };
  }
  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme }) => theme.lightBlue };
    outline: none;
  }
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function NumericalInput({ 
    onUserInput, 
    placeholder,
    background,
    value,
    ...rest 
}: {
    onUserInput: (value: string) => void
    placeholder: string
    background?: string
} & InputHTMLAttributes<HTMLInputElement> ) {

    const enforcer = (nextUserInput: string) => {
        if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
            onUserInput(nextUserInput === '' ? placeholder : nextUserInput)
        }
    }

    return (
        <StyledInput
            {...rest}
            align='right'
            background={background}
            onChange={(event) => {
                enforcer(event.target.value.replace(/,/g, '.'))
            }} 
            value={value}     
            inputMode="decimal"
            autoComplete="off"
            autoCorrect="off"
            // text-specific options
            type="text"
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder={placeholder}
            minLength={1}
            maxLength={79}
            spellCheck="false"
        />
    )
}

