//COMPONENTS
import { InfoBox }from '../InfoBox'
import { TextWrapper } from '../../theme/components'
import { AutoColumn, ColumnCenter } from '../Column'
import Row from '../Row'
import LiquidityResult from './LiquidityResult'
import FeesResult from './FeesResult'
import GasFeesResult from './GasFeesResult'
import YieldResult from './YieldResult'
import DateSlider from './DateSlider'
import ImpermanetLossResult from './ImpermanetLossResult'

//LIBS
import { useContext } from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'

//UNISWAPV3
import { Position } from '@uniswap/v3-sdk'

//CORE
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'


//REACT
import { useState, useCallback, Dispatch, SetStateAction } from 'react'

//INTERFACES
import { PoolHourData, BlockData, FiatGasFees, DatesInterface } from '../../interfaces'

//ENUMS
import { Positions, BlockType } from '../../constants/enums'

const PositionResultsWrapper = styled.div`
    background-color: ${({ theme }) => theme.darkBlue };
    border-radius: 16px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        grid-row: 2 / 3;
        grid-column: 1 / 3
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin: 10px 30px;
        padding: 10px 34px;
    `}
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 10px 10px;
        padding: 10px 8px;
    `}
`

const StyledAutoColumn = styled.div`
    display: grid;
    grid-auto-rows: auto;
    width: 100%;
    grid-gap: 8px;
    padding: 8px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: flex;
        flex-direction: row;
    `};

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: grid;
        grid-auto-rows: auto;
        width: 100%;
        grid-row-gap: 0px;
    `};
`

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 10px;
        width: 100%;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 4px 0;
    `};
`

export default function PositionResults({ 
    currencyA, 
    currencyB, 
    blocks, 
    positions,
    poolHourData,
    isLoading,
    isError,
    onNewSliderDate,
    sliderDate,
    sorted,
    chainId
}: {
    currencyA: Currency
    currencyB: Currency | undefined
    positions:  { [position in Positions]?: Position }
    blocks: { [block in BlockType]?: BlockData }
    poolHourData?: PoolHourData[]
    isLoading: boolean
    isError: boolean
    onNewSliderDate: Dispatch<SetStateAction<DatesInterface>>
    sliderDate: Date | null
    sorted: boolean | undefined
    chainId: number
}) {
    const [liquidityIn, setLiquidityIn] = useState<CurrencyAmount<Token>>()
    const [liquidityOut, setLiquidityOut] = useState<CurrencyAmount<Token>>()
    const [fiatValueOfFees, setNewFiatOfValueFees] = useState<CurrencyAmount<Token>>()
    const [fiatGasFees, setNewFiatGasFees] = useState<FiatGasFees>()

    const handleNewFiatValue = useCallback((
        value: CurrencyAmount<Token> | null, out: boolean
    ) => {
        if(value && !out) setLiquidityIn(value)
        if(value && out) setLiquidityOut(value)
    }, [])

    return(
        <PositionResultsWrapper>
                <ColumnCenter>
                    <TextWrapper fontSize={3} paddingTop={1}>
                        Position Results
                    </TextWrapper>
                    <StyledAutoColumn>
                        <StyledWrapper>
                            <LiquidityResult 
                                out={false} 
                                currencyA={currencyA} 
                                currencyB={currencyB} 
                                position={positions.IN} 
                                block={blocks?.IN?.number} 
                                onNewFiatValue={handleNewFiatValue}
                                chainId={chainId}
                                sorted={sorted}/>
                            <LiquidityResult 
                                out={true} 
                                currencyA={currencyA} 
                                currencyB={currencyB} 
                                position={positions.SLIDER ? positions.SLIDER : positions.OUT} 
                                block={blocks.SLIDER ? blocks.SLIDER.number : blocks?.OUT?.number} 
                                onNewFiatValue={handleNewFiatValue}
                                chainId={chainId}
                                sorted={sorted}/>
                            <FeesResult
                                currencyA={currencyA} 
                                currencyB={currencyB}
                                data={poolHourData} 
                                isLoading={isLoading} 
                                isError={isError} 
                                ticks={[positions?.IN?.tickLower, positions?.IN?.tickUpper]} 
                                sorted={sorted}
                                positionOut={positions.SLIDER ? positions.SLIDER : positions.OUT}
                                liquidityIn={positions?.IN?.liquidity.toString()}
                                block={blocks.SLIDER ? blocks.SLIDER.number : blocks?.OUT?.number}
                                onNewFiatValueOfFees={setNewFiatOfValueFees}
                                chainId={chainId}/>
                        </StyledWrapper>
                        <StyledWrapper>
                            <GasFeesResult blocks={blocks} onNewFiatGasFees={setNewFiatGasFees} chainId={chainId}/>
                            <YieldResult 
                                liquidityIn={liquidityIn} 
                                liquidityOut={liquidityOut} 
                                earnedfees={fiatValueOfFees} 
                                gasFees={fiatGasFees}/>
                            <ImpermanetLossResult />
                        </StyledWrapper>
                    </StyledAutoColumn>
                    <DateSlider data={poolHourData} onNewSliderDate={onNewSliderDate} date={sliderDate}/>
                </ColumnCenter>
        </PositionResultsWrapper>
    )
}
