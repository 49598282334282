import styled, { ThemeContext } from 'styled-components/macro'
import Row from '../Row'
import { Currency } from '@uniswap/sdk-core';
import ethereumLogo from '../../assets/ethereumLogo.png'
import { WrappedTokenInfo } from "../../utils/WrappedTokenInfo";
import { NumericalInput } from '../Input'
import { useCallback, useContext } from 'react'
import { FocusedToken } from '../../constants/enums';
import { TokenIcon, TextWrapper } from '../../theme/components'
import { Box } from 'rebass/styled-components'
import { BaseButton, BaseButtonChildren }from '../Buttons'


export const StyledColumn= styled(Box)<{ locked?: boolean }>`
    border-radius: 20px;
    width: 100%;
    border: ${({ theme }) => '2px solid ' + theme.lightBlue };
    :hover {
        border: ${ props => props.locked ? '2px solid transparent' : '2px solid ' + props.theme.darkBlue };
    }
`

const StyledRow = styled(Row)`
    white-space: nowrap
`

export default function  CurrencyInputPanel({ 
    locked = false, 
    currency,
    currentFocus,
    focusFor, 
    handleSetDependency, 
    onUserInput, 
    value,
    date
}: { 
    locked: boolean
    currency?: Currency
    currentFocus: FocusedToken
    focusFor: FocusedToken
    handleSetDependency: (dependecyFor: FocusedToken) => void
    onUserInput: (value: string) => void
    value?: string;
    date: Date
 }) {
    const theme = useContext(ThemeContext)

    const onKeyDown = useCallback(() => {
        if(currentFocus === focusFor) return
        handleSetDependency(focusFor)

    }, [currentFocus, focusFor, handleSetDependency])


    return(
        <StyledColumn locked={locked}>
            { locked ? (
                <Row p={3} fontSize={1}>
                    <TextWrapper>
                        The market price was outside your specified price range on 
                        {' '}{date.toDateString().split(' ')[1]} {date.toDateString().split(' ')[2]}. 
                        Single-asset deposit only.
                    </TextWrapper>
                </Row>
            ) :  (
                <Row p={3} justify={'space-between'}>
                    <BaseButton paddingLeft={1} paddingRight={5}>
                        <StyledRow justify={'start'} marginRight={1}> 
                        { currency ? <TokenIcon src={ currency instanceof WrappedTokenInfo ? currency.tokenInfo.logoURI : ethereumLogo }/> : null }
                        { currency ? (
                            <TextWrapper fontSize={3} marginLeft={1}>
                                { currency ? currency.isToken ? currency.wrapped.symbol : 'ETH' : null }
                            </TextWrapper>
                        ) : <TextWrapper>Select Token</TextWrapper> }
                        </StyledRow>
                    </BaseButton>
                    <NumericalInput
                        background={theme.darkBlue}
                        onUserInput={onUserInput}
                        placeholder={'0.0'}
                        onKeyDown={onKeyDown}
                        value={value}
                    />
                </Row> 
            )
        }
        </StyledColumn>
    )
}




