import styled from 'styled-components/macro'
import { CHAIN_INFO } from '../../constants/chains'
import { ChevronDown } from 'react-feather'
import Row from '../Row'
import { AutoColumn } from '../Column'
import { SupportedChainId } from '../../constants/chains'
import { TextWrapper } from '../../theme/components'
import { HeaderModals } from './index'
import useClickOutside from '../../hooks/useClickOutside'
import { useCallback, useRef } from 'react'

const SelectioWrapper = styled(Row)`
    position: relative;
`

const SelectorControls = styled.div`
    background-color: ${({ theme }) => theme.darkBlue };
    border-radius: 16px;
    font-weight: 500;
    padding: 8px 10px;
    cursor: pointer;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 6px;
`

const ActiveSelectorLabel = styled.div`
    margin-right: 8px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none
    `}
`

const FlyoutMenu = styled(AutoColumn)`
    font-family:  ${({ theme }) => theme.fontFamily };        
    background-color: ${({ theme }) => theme.darkBlue };
    border: ${({ theme }) => `1px solid ` + theme.lightBlue };
    border-radius: 20px;
    padding: 14px 10px;
    position: absolute;
    top: 60px;
    right: -50px;
    width: 272px;
    z-index: 99;
`
const FlyoutHeader = styled.div`
  font-weight: 400;
`

const RowItemWrapper = styled(Row)<{ active?: boolean }>`
    background-color: ${({ theme, active }) => active ? theme.doubleDarkBlue : theme.darkBlue };
    border-radius: 16px;
    &:hover {
        border: none
    }
    cursor: pointer;
`

const SelectorLabel = styled.div`
    margin-left: 8px;
`

const StyledChevronDown = styled(ChevronDown)`
  width: 16px;
`


function GetFlyOutRow({ 
    chainId,
    onActiveChainIdChange,
    manageModal
} : { 
    chainId: SupportedChainId,
    onActiveChainIdChange: (chainId: SupportedChainId) => void,
    manageModal: (modal: HeaderModals.NONE) => void
 } ) {

    const info = CHAIN_INFO[chainId]

    return (
        <RowItemWrapper onClick={() => {
            onActiveChainIdChange(chainId)
            manageModal(HeaderModals.NONE)
        }}>
            <Row padding={'6px 6px'}>
                <Logo src={info.logoUrl}></Logo>
                <SelectorLabel>{info.label}</SelectorLabel>
            </Row>
        </RowItemWrapper>
    )
}


export default function NetworkSelector({ 
    activeHeaderModal, 
    onHeaderModalChange,
    activeChainId,
    onActiveChainIdChange
}: {
    activeHeaderModal: HeaderModals; 
    onHeaderModalChange: (modal: HeaderModals) => void
    activeChainId: SupportedChainId
    onActiveChainIdChange: (chainInd: SupportedChainId) => void
}) {
    const info = CHAIN_INFO[activeChainId]
    const open = activeHeaderModal === HeaderModals.NETWORK ? true : false

    const node = useRef<HTMLDivElement>(null)
    const handler = useCallback(() => (onHeaderModalChange(HeaderModals.NONE)), [onHeaderModalChange])
    
    useClickOutside(node, handler, open)

    return (
        <SelectioWrapper ref={node} justify={'end'}>
            <SelectorControls onClick={() => onHeaderModalChange(HeaderModals.NETWORK)} >
                <Logo src={info.logoUrl}></Logo>
                <ActiveSelectorLabel>{info.label}</ActiveSelectorLabel>
                <StyledChevronDown />
            </SelectorControls>
            { open ? (
                    <FlyoutMenu gap={'md'} onMouseLeave={() => onHeaderModalChange(HeaderModals.NONE)}>
                        <FlyoutHeader>
                            <TextWrapper>
                                Select Network
                            </TextWrapper> 
                        </FlyoutHeader>
                        <AutoColumn gap={'sm'} justify={'center'}>
                            <GetFlyOutRow 
                                chainId={SupportedChainId.MAINNET}
                                onActiveChainIdChange={onActiveChainIdChange}
                                manageModal={onHeaderModalChange}
                            ></GetFlyOutRow>
                            <GetFlyOutRow 
                                chainId={SupportedChainId.OPTIMISM}
                                onActiveChainIdChange={onActiveChainIdChange}
                                manageModal={onHeaderModalChange}
                            ></GetFlyOutRow>
                            <GetFlyOutRow 
                                chainId={SupportedChainId.POLYGON}
                                onActiveChainIdChange={onActiveChainIdChange}
                                manageModal={onHeaderModalChange}
                            ></GetFlyOutRow>
                            <GetFlyOutRow 
                                chainId={SupportedChainId.ARBITRUM_ONE}
                                onActiveChainIdChange={onActiveChainIdChange}
                                manageModal={onHeaderModalChange}
                            ></GetFlyOutRow>
                        </AutoColumn>
                    </FlyoutMenu>
                ) 
                : null 
            }
        </SelectioWrapper>
    )
}