import { useEffect, RefObject } from "react";

export default function useClickOutside(node: RefObject<HTMLDivElement>, handler: () => void, open: boolean) {
    useEffect(() => {
        if(!open) return 
        function listener(event: MouseEvent) {
            if(!node.current || node.current.contains(event.target ? event.target as Node : null)) return 
            handler()
        }
        document.addEventListener('mousedown', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
        }
    }, [handler, node, open])
}