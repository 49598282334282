
import { tickToPrice } from '@uniswap/v3-sdk'
import { getNearestUsableTick, feeTierToTickSpacing } from '../utils/V3Utils'
import { useState, useMemo } from 'react'
import { MIN_TICK, MAX_TICK } from '../constants/v3'


export default function useGetTicksToFetch(feeTier: number, tickCenter: number | undefined, ticks: [number, number] | null) {
    const [ticksToFetch, setTicksToFetch] = useState(200)

    const tickSpacing = feeTierToTickSpacing(feeTier)

    const [tickLowerWithMargin, tickUpperWithMargin] = useMemo(() => {
        if(!ticks || !ticks.length) return [undefined, undefined]
            const [tickLower, tickUpper] = [...ticks]
            return [tickLower - tickSpacing, tickUpper + tickSpacing]
    }, [tickSpacing, ticks])

    if(!tickCenter || !ticks || !tickLowerWithMargin || !tickUpperWithMargin) return

    const nearestUsableTick = tickCenter ? getNearestUsableTick(tickCenter, tickSpacing) : undefined
    const minTickUnchecked = nearestUsableTick  ? nearestUsableTick -  (ticksToFetch *  tickSpacing) : undefined
    const maxTickUnchecked = nearestUsableTick ? nearestUsableTick +  (ticksToFetch *  tickSpacing) : undefined
    const minTick = minTickUnchecked && minTickUnchecked > MIN_TICK ? minTickUnchecked : MIN_TICK
    const maxTick = maxTickUnchecked && maxTickUnchecked < MAX_TICK ? maxTickUnchecked : MAX_TICK

    if(tickLowerWithMargin < minTick) {
        const lowerDifference = Math.ceil((minTick - tickLowerWithMargin) / tickSpacing)
        setTicksToFetch(ticksToFetch + lowerDifference)
    } else if(maxTick < tickUpperWithMargin) {
        const upperDifference = Math.ceil((tickUpperWithMargin - maxTick) / tickSpacing)
        setTicksToFetch(ticksToFetch + upperDifference)
    }

    return ticksToFetch
}
