import styled from 'styled-components/macro'

export const ChartWrapper = styled.div`
    background-color: ${({ theme }) => theme.darkBlue };
    border-radius: 16px;
    position: relative;
    padding: 14px 20px;
    height: 100%;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin: 10px 30px;
        padding: 10px 34px;
    `}
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 10px 10px;
        padding: 10px 8px;
    `}
`
