import { gql, ApolloError, useQuery } from "@apollo/client"
import { useState, useEffect } from 'react'
import { SupportedChainId } from "../constants/chains"
import useGetClients from './useGetClients'

const GETTOPTOKENS = gql`
    query getTopTokens($numberOfTopTokens: Int) {
        tokens(first: $numberOfTopTokens, orderBy: volumeUSD, orderDirection: desc) {
            id
        }
    }
`

export default function useFetchTopTokens(chainId: number, numberOfTopTokens: number = 4) : { 
    data: string[] | undefined, 
    loading: boolean, 
    error: ApolloError | undefined,
}{
    const { client } = useGetClients(chainId)

    const { data, loading, error } = useQuery(GETTOPTOKENS, {
        client,
        variables: {
            numberOfTopTokens
        }
    })

    if(data && !loading && !error) {
        return {
            data: data.tokens.map(({ id }: { id: string }) => id),
            loading,
            error
        }
    }

    return {
        data: undefined,
        loading,
        error
    }
}

