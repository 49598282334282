import { startOfDay, endOfDay, eachDayOfInterval, startOfWeek, endOfWeek, format, isSameDay, isBefore, isAfter, isWeekend, isWithinInterval } from 'date-fns'
import { getMonthDisplayRange } from '../../../utils/DatesRangeUtils'
import DayCell from '../DayCell'
import { DatesInterface } from '../../../interfaces'
import styled from 'styled-components'

const MonthWrapper = styled.div`
    width: 27.667em;
    padding: 0 0.833em 0.7em 0.833em;

`
const WeekDraysWrapper = styled.div`
    display: flex;
`
const WeekDayWrapper = styled.span`
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
`
const DaysWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export default function Month({ 
    minDateProp, 
    maxDateProp, 
    month, 
    dateOptions, 
    weekdayDisplayFormat, 
    onMouseLeave, 
    preview, 
    onDragSelectionStart,
    onDragSelectionEnd,
    onDragSelectionMove ,
    onPreviewChange,
    range,
    dayDisplayFormat
}: {
    minDateProp?: Date
    maxDateProp?: Date
    month: Date
    dateOptions: { locale: Locale }
    weekdayDisplayFormat: string
    onMouseLeave: () => void
    preview: null | DatesInterface
    onDragSelectionStart: (date: Date) => void
    onDragSelectionEnd: (date: Date) => void
    onDragSelectionMove: (date: Date) => void
    onPreviewChange:  (val?: Date) => void
    range: DatesInterface
    dayDisplayFormat: string
} ) {
    const now = new Date()
    const minDate = minDateProp && startOfDay(minDateProp)
    const maxDate = maxDateProp && endOfDay(maxDateProp)
    const monthDisplay = getMonthDisplayRange(
        month,
        dateOptions
    )
    return (
        <MonthWrapper>
            {renderWeekdays(dateOptions, weekdayDisplayFormat)}
            <DaysWrapper onMouseLeave={onMouseLeave}>
                {eachDayOfInterval({ start: monthDisplay.start, end: monthDisplay.end }).map((day: Date, i: number) => {
                    const isStartOfMonth = isSameDay(day, monthDisplay.startDateOfMonth)
                    const isEndOfMonth = isSameDay(day, monthDisplay.endDateOfMonth)
                    const isOutsideMinMax =
                    (minDate && isBefore(day, minDate)) || (maxDate && isAfter(day, maxDate))
                    return (
                        <DayCell
                            range={range}
                            day={day}
                            preview={preview}
                            isWeekend={isWeekend(day)}
                            isToday={isSameDay(day, now)}
                            isStartOfWeek={isSameDay(day, startOfWeek(day, dateOptions))}
                            isEndOfWeek={isSameDay(day, endOfWeek(day, dateOptions))}
                            isStartOfMonth={isStartOfMonth}
                            isEndOfMonth={isEndOfMonth}
                            key={i}
                            disabled={isOutsideMinMax}
                            isPassive={
                                !isWithinInterval(day, {
                                  start: monthDisplay.startDateOfMonth,
                                  end: monthDisplay.endDateOfMonth,
                                })
                            }
                            onMouseDown={onDragSelectionStart}
                            onMouseUp={onDragSelectionEnd}
                            onMouseEnter={onDragSelectionMove}
                            onPreviewChange={onPreviewChange}
                            dayDisplayFormat={dayDisplayFormat}
                        ></DayCell>
                    )
                })}
            </DaysWrapper>
        </MonthWrapper>
    )
}

function renderWeekdays(dateOptions: { locale: Locale }, weekdayDisplayFormat: string) {
    const now = new Date();
    return (
      <WeekDraysWrapper>
        {eachDayOfInterval({
          start: startOfWeek(now, dateOptions),
          end: endOfWeek(now, dateOptions),
        }).map((day, i) => (
          <WeekDayWrapper key={i}>
            {format(day, weekdayDisplayFormat, dateOptions)}
          </WeekDayWrapper>
        ))}
      </WeekDraysWrapper>
    );
  }



