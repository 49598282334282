import { client, blocksClient, arbitrumClient, arbitrumBlockClient, optimismClient, optimismBlockClient, polygonClient, polygonBlockClient } from '../apollo'
import { SupportedChainId } from '../constants/chains';

export default function useGetClients(chainId: number) {
    switch(chainId) {
        default:
            return { client: client, blockClient: blocksClient }
        case SupportedChainId.MAINNET:
            return  { client: client, blockClient: blocksClient }
        case SupportedChainId.ARBITRUM_ONE:
          return { client: arbitrumClient, blockClient: arbitrumBlockClient }
        case SupportedChainId.OPTIMISM:
          return { client: optimismClient, blockClient: optimismBlockClient }
        case SupportedChainId.POLYGON:
          return { client: polygonClient, blockClient: polygonBlockClient }
    }
}