import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core'
import { ApolloProvider } from "@apollo/client";
import { client } from './apollo'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme'
import getLibrary from './utils/getLibrary'
import useOnChainGetBlockNumber from './hooks/useOnChainGetBlockNumber';
import { useMulticallContract } from './hooks/useContract';
import { Provider } from 'react-redux'
import store from './state/store'
import { multicall } from './state/store'



function MulticallUpdater() {

  const blockNumber = useOnChainGetBlockNumber()
  const { chainId } = useWeb3React()
  const multicallContract = useMulticallContract()

  return <multicall.Updater chainId={chainId} latestBlockNumber={blockNumber} contract={multicallContract} />
}


ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <MulticallUpdater />
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            <MulticallUpdater />
          </Web3ReactProvider>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
