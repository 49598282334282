import { gql } from "@apollo/client";
import { DatesInterface, UnformattedPoolHourData, PoolHourData } from '../interfaces'
import { getUnixTime } from 'date-fns'
import { useEffect, useState, useMemo } from "react";
import JSBI from 'jsbi'
import useGetClients from './useGetClients'

const getPoolHourData = () => gql`
    query GetPoolHourData($address: ID!, $start: Int, $end: Int) {
        pool(id: $address) {
            poolHourData(first: 1000, where: { periodStartUnix_gte: $start, periodStartUnix_lte: $end }) {
                periodStartUnix
                token0Price	
                token1Price
                tick
                feeGrowthGlobal0X128
                feeGrowthGlobal1X128
            }
        }
    }
`

export function useFetchPoolHourData(
    range: DatesInterface,
    chainId: number,
    address?: string
): { 
    formattedPoolHourData: PoolHourData[] | undefined, 
    loading: boolean, 
    error: boolean 
}  {
    const [poolHourData, setPoolHourData] = useState<UnformattedPoolHourData[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const { client } = useGetClients(chainId)

    const { startDate, endDate } = range

    useEffect(() => {

        if(!address || !startDate || !endDate) return

        let allData: UnformattedPoolHourData[] = []

        let start = getUnixTime(startDate)
        const end = getUnixTime(endDate)

        async function fetchPoolHourData() {
            setLoading(true)
            async function queryPoolHourData() {
                 return await client.query({
                    query: getPoolHourData(),
                    variables: {
                        start,
                        end,
                        address
                    }
                })
            }

            const { data, loading, error } = await queryPoolHourData()
            
            if(!data && (loading || error)) {
                setLoading(loading); setError(Boolean(error))
            }

            if(data.pool.poolHourData.length < 1000) {                
                allData = allData.concat(data.pool.poolHourData)
                setPoolHourData(allData)
                
            } else {
                allData = allData.concat(data.pool.poolHourData)
                start = allData[allData.length - 1].periodStartUnix
                await fetchPoolHourData()
            }
            return setLoading(false)
        }
        fetchPoolHourData()

    }, [endDate, address, startDate])

    
    const formattedPoolHourData = useMemo(() => {
        if(!poolHourData) return
        return poolHourData.map(({ periodStartUnix, token0Price, token1Price, tick, feeGrowthGlobal0X128, feeGrowthGlobal1X128 }) => {
            return { 
                periodStartUnix: new Date(periodStartUnix * 1000),
                token0Price: parseFloat(token0Price),
                token1Price: parseFloat(token1Price),
                tick: parseInt(tick),
                feeGrowthGlobal0X128: JSBI.BigInt(feeGrowthGlobal0X128) ,
                feeGrowthGlobal1X128: JSBI.BigInt(feeGrowthGlobal1X128),
            }
        })

    }, [poolHourData])

    return {
        formattedPoolHourData,
        loading,
        error
    }
}