import { useState, useRef, useCallback, MouseEvent } from 'react'
import DateInput from '../DateInput'
import defaultLocale from 'date-fns/locale/en-US';
import { isSameDay, addMonths, setMonth, setYear, min, max } from 'date-fns'
import { DatesInterface } from '../../../interfaces'
import { calcFocusDate } from '../../../utils/DatesRangeUtils'
import Month from '../Month'
import styled from 'styled-components/macro'
import useClickOutside from '../../../hooks/useClickOutside';
import { darken } from 'polished'

export type Drag = {
    status: boolean
    range: { startDate?: Date | null; endDate?: Date | null }
    disablePreview?: boolean
}

const DateRangeWrapper = styled.div`
    user-select: none;
`

const CalendarWrapper = styled(DateRangeWrapper)`
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.blue };
    border:  ${({ theme }) => '1px solid ' + theme.lightBlue };
    border-radius: 0.5rem;
    display: inline-flex;
    flex-direction: column;
    color: ${({ theme }) => theme.white };
    font-size: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
`

const DateDisplayWrapper = styled.div`
    padding: 6px;
    display: flex;
    justify-content: space-between;
`

const MonthsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledDateInput = styled(DateInput)<{ focused: boolean }>`
    border-radius: 4px;
    border: ${({ theme }) => '2px solid ' +  theme.lightBlue };
    input {
        cursor: pointer;
        height: 1.8rem;
        line-height: 2.5em;
        border: 0px;
        background: transparent;
        width: 100%;
        color: ${({ theme }) => theme.white };
    };
    flex: 1 1;
    text-align: center;
    color: inherit;
    & + & {
      margin-left: 0.833em;
    };
    input {
      text-align: inherit;

      &:disabled {
        cursor: default;
      };
      &:focus {
          outline: none;
      }
    };
`

const MonthAndYearWrapper = styled.div`
    align-items: center;
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
`

const NextPrevButton = styled.button`
    box-sizing: inherit;
    cursor: pointer;
    outline: none;
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: ${({ theme }) => theme.lightBlue };
    &:hover {
        background-color: ${({ theme }) => darken(0.08, theme.lightBlue)};
    }
    i {
        display: block;
        width: 0;
        height: 0;
        padding: 0;
        text-align: center;
        border-style: solid;
        margin: auto;
        transform: translate(-3px, 0px);
    }
`

const PrevButton = styled(NextPrevButton)`
    i {
        border-width: 4px 6px 4px 4px;
        border-color: transparent rgb(52, 73, 94) transparent transparent;
        transform: translate(-3px, 0px);
    } 
`

const NextButton = styled(NextPrevButton)`
    i {
        margin: 0 0 0 7px;
        border-width: 4px 4px 4px 6px;
        border-color: transparent transparent transparent rgb(52, 73, 94);
        transform: translate(3px, 0px);
    }
`

const MonthAndYearPickers = styled.span`
    font-weight: 600;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.white };
    select {
        appearance: none;
        -webkit-appearance: none;
        border: 0;
        background: transparent;
        padding: 2px 30px 2px 10px;
        border-radius: 4px;
        outline: 0;
        color: ${({ theme }) => theme.white };
        background-position: right 8px center;
        cursor: pointer;
        text-align: center;
    &:hover {
            background-color: ${({ theme }) => theme.white };
        }
    }
`

const MonthPicker = styled.span`
    margin: 0 5px
`

const StyledSelect = styled.select`
    padding: 10px;
`

const YearPicker = styled.span`
    margin: 0 5px
`



export default function Calendar({
    focusedDate, 
    dateDisplayFormat='MMM d, yyyy', 
    locale=defaultLocale,
    range,
    onChange,
    updateRange,
    onRangeFocusChange,
    minDate,
    maxDate,
    months=1,
    weekdayDisplayFormat='E',
    dayDisplayFormat='d',
    onPreviewChange,
    preview,
    onHandleRenderCalendar,
    renderCalendar
}: {
    focusedDate: number
    dateDisplayFormat?: string
    locale?: Locale
    range: DatesInterface
    onChange?: (date: Date) => void
    updateRange?: (date: DatesInterface) => void
    onRangeFocusChange?: (focusDate: number) => void 
    maxDate: Date
    minDate: Date
    months?: number
    weekdayDisplayFormat?: string
    dayDisplayFormat?: string,
    onPreviewChange: (val?: Date) => void
    preview: null | DatesInterface
    onHandleRenderCalendar: (render: boolean) => void
    renderCalendar: boolean
} ): JSX.Element {

    const [drag, setDrag] = useState<Drag>({
        status: false,
        range: { startDate: null, endDate: null },
        disablePreview: false,
    })

    const [focused, setFocused] = useState<Date>(calcFocusDate(null, months, range))

    const getMonthNames = () => {
        return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].map((i) => locale.localize?.month(i))
      }
    
    const monthNames = getMonthNames()

    const dateOptions = { locale: locale }

    const onDragSelectionEnd = (date: Date) => {
        if (!drag.status || !drag.range.startDate) {
            onChange && onChange(date)
            return
        }
        const newRange = {
            startDate: drag.range.startDate,
            endDate: date,
        };
          
        if (isSameDay(newRange.startDate, date)) {
            setDrag({ status: false, range: {} }) 
            onChange && onChange(date)
        } else {
            setDrag({ status: false, range: {}})
            updateRange && updateRange({...newRange, sliderDate: null, today: new Date()})
        }
        
    }

    const handleRangeFocusChange = (focusDate: number) => {
        onRangeFocusChange && onRangeFocusChange(focusDate)
    }

    const changeShownDate = (value: number, mode = 'set') => {
        const modeMapper: { [key: string]: (() => Date | number)} = {
          monthOffset: () => addMonths(focused, value),
          setMonth: () => setMonth(focused, value),
          setYear: () => setYear(focused, value),
          set: () => value
        }
    
        const newDate = min([max([modeMapper[mode](), minDate]), maxDate]);
        setFocused(newDate)
    }

    const onDragSelectionStart = (date: Date) => {
        setDrag({
            status: true,
            range: { startDate: date, endDate: date },
            disablePreview: true
        })
    }

    const onDragSelectionMove = (date: Date) => {
        if (!drag.status) return
        setDrag({
            status: drag.status,
            range: { startDate: drag.range.startDate, endDate: date },
            disablePreview: true
        })
    }

    const renderMonthAndYear = () => {
        const upperYearLimit = (maxDate).getFullYear()
        const lowerYearLimit = (minDate).getFullYear()
        return (
            <MonthAndYearWrapper onMouseUp={e => e.stopPropagation()}>
                <PrevButton
                    type="button"
                    onClick={() => changeShownDate(-1, 'monthOffset')}
                    >
                    <i />
                </PrevButton>
                <MonthAndYearPickers>
                    <MonthPicker>
                        <StyledSelect
                            value={focused.getMonth()}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                changeShownDate(parseInt(e.target.value), 'setMonth')
                        }>
                        {monthNames.map((monthName: string, i: number) => (
                            <option key={i} value={i}>
                                {monthName}
                            </option>
                        ))}
                        </StyledSelect>
                    </MonthPicker>
                    <YearPicker>
                        <select
                            value={focused.getFullYear()}
                            onChange={(e: any)=> changeShownDate(e.target.value, 'setYear')}
                            >
                            {new Array(upperYearLimit - lowerYearLimit + 1)
                            .fill(upperYearLimit)
                            .map((val, i) => {
                                const year = val - i;
                                return (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                                );
                            })}
                        </select>
                    </YearPicker>
                </MonthAndYearPickers>
                <NextButton
                    type="button"
                    onClick={() => changeShownDate(+1, 'monthOffset')}
                    >
                <i />
                 </NextButton>
            </MonthAndYearWrapper>
        )
    }

    const renderDateDisplay = () => {
        return (
            <DateDisplayWrapper>
                <StyledDateInput
                    focused={focusedDate === 0}
                    dateDisplayFormat={dateDisplayFormat}
                    dateOptions={dateOptions}
                    value={range.startDate}
                    onChange={onDragSelectionEnd}
                    onFocus={() => handleRangeFocusChange(0)}
                ></StyledDateInput>
                <StyledDateInput
                    focused={focusedDate === 1}
                    dateDisplayFormat={dateDisplayFormat}
                    dateOptions={dateOptions}
                    value={range.endDate}
                    onChange={onDragSelectionEnd}
                    onFocus={() => handleRangeFocusChange(1)}
                ></StyledDateInput>
            </DateDisplayWrapper>
        )
    }

    const node = useRef<HTMLDivElement>(null)

    const handler = useCallback(() => onHandleRenderCalendar(false), [onHandleRenderCalendar])
    
    useClickOutside(node, handler, renderCalendar)

    return (
        <DateRangeWrapper  ref={node} onClick={(event: MouseEvent) => {
            event.stopPropagation()
            if(!renderCalendar) {
                onHandleRenderCalendar(true)
            }
        }}>
            <CalendarWrapper
            onMouseUp={() => setDrag({status: false, range: {} })}
            onMouseLeave={() => { setDrag({status: false, range: {}  })}}>

            {renderDateDisplay()}
            { renderCalendar && renderMonthAndYear() }
            { renderCalendar && 
                <MonthsWrapper>
                    <Month 
                        month={focused} 
                        minDateProp={minDate} 
                        maxDateProp={maxDate} 
                        dateOptions={dateOptions}
                        weekdayDisplayFormat={weekdayDisplayFormat}
                        onMouseLeave={() => onPreviewChange && onPreviewChange()}
                        preview={preview}
                        onDragSelectionStart={onDragSelectionStart}
                        onDragSelectionEnd={onDragSelectionEnd}
                        onDragSelectionMove={onDragSelectionMove}
                        onPreviewChange={onPreviewChange}
                        range={range}
                        dayDisplayFormat={dayDisplayFormat}
                    ></Month>
                </MonthsWrapper>
            }
            </CalendarWrapper>
        </DateRangeWrapper>
    )
}

