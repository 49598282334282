import { RefObject, useRef, useEffect, useContext, useState } from 'react'
import { D3BrushEvent, ZoomTransform, BrushBehavior, brushY,  select } from 'd3'
import { ThemeContext } from 'styled-components/macro'
import { Margin } from '../../interfaces'


interface BrushProps {
    group: RefObject<SVGGElement>
    margin: { top: number; right: number; bottom: number; left: number }
    widthExtent: number
    heightExtent: number
    brushExtent: [number, number] | null | undefined
    handleBrushMove: (event: D3BrushEvent<unknown>) => void
}

export default function Brush({ group, margin, widthExtent, heightExtent, brushExtent, handleBrushMove }: BrushProps ) {

    /* State for displaying ChartPriceHandlers once the brush behavioor has selected a group 
        otherwise the brush will overlay the custom ChartPriceHandlers */
    const [brushActive, setBrushActive] = useState(false)

    const brush = useRef<BrushBehavior<unknown>>(
        brushY()
            .extent([[0, 0], [widthExtent, heightExtent]])
            .on('start brush end', handleBrushMove).handleSize(30)
    )

    useEffect(() => {
        if(!group.current || !brush.current) return
        brush.current(select(group.current))
        if(!brushActive) setBrushActive(true)
    }, [brushActive, group])


    useEffect(() => {
        if(!group.current || !brush.current || !brushExtent) return
        brush.current.move(select(group.current), brushExtent)
    }, [brushExtent, group])


    return (
        (brushExtent && brushActive) ? (
            <ChartPriceHandlers 
                cords={brushExtent} 
                width={widthExtent} 
                margin={margin} 
                height={heightExtent}/>
        ) : null
    )
}

const pathHandler = (cords: [number, number], margin: Margin, width: number, height: number, id: 'a' | 'b') => {
    let handlerPath;
    let handlerDetails;

    const cord = id === 'a' ? cords[0] : cords[1]

    if((id === 'a' && cord <= 5) || (id === 'b' && !(cord >= height - 5))) {
        handlerPath = [
            `M ${0} ${cord}`,
            `v12`, 
            `q0,2 2,2`,
            `h16`, 
            `q2,0 2,-2`,
            `v-12`
        ].join(' ')
        handlerDetails = [
            `M ${4} ${cord + 4}`,
            `h12,0`,
            `M ${4} ${cord + 8}`,
            `h12,0`
        ].join(' ')
    } else {
        handlerPath = [
            `M ${0} ${cord}`,
            `v-12`, 
            `q0,-2 2,-2`, 
            `h16`,
            `q2,0 2,2`,
            `v12`
        ].join(' ')
        handlerDetails = [
            `M ${4} ${cord - 4}`, 
            `h12,0`,
            `M ${4} ${cord  - 8}`, 
            `h12,0`
        ].join(' ')
    }

    return [handlerPath, handlerDetails]
}


function ChartPriceHandlers({ 
    cords, 
    width,
    height, 
    margin 
}: {
    cords: [number, number]
    width: number
    height: number
    margin: { top: number; right: number; bottom: number; left: number }
} ) {
    const theme = useContext(ThemeContext)

    return(
        <g>
            <path 
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={`M ${0},${cords[0]} h${width}`} 
                stroke={theme.lightBlue}
                strokeWidth={1.5} 
            />
            <path 
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={pathHandler(cords, margin, width, height, 'a')[0]} 
                stroke={theme.lightBlue}
                strokeWidth="1.5" 
                fill={theme.lightBlue} 
            />
            <path 
                d={pathHandler(cords, margin, width, height, 'a')[1]} 
                stroke={theme.white}
                strokeWidth="1"
            />
            <path 
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={`M ${0} ${cords[1]} h ${width}`} 
                stroke={theme.lightBlue} 
                strokeWidth={1.5} 
            />
            <path 
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={pathHandler(cords, margin, width, height, 'b')[0]} 
                stroke={theme.lightBlue}
                strokeWidth="1.5" 
                fill={theme.lightBlue} 
            />
            <path 
                d={pathHandler(cords, margin, width, height, 'b')[1]}
                stroke={theme.white}
                strokeWidth="1"
            />
        </g>
    )
}
