import { Token, Currency } from '@uniswap/sdk-core'
import { TokenInfo, TokenList } from '../../interfaces'
import { isAddress, getAddress } from '@ethersproject/address'

export class WrappedTokenInfo implements Token {
    public readonly isNative = false
    public readonly isToken = true
    public readonly list?: TokenList

    public readonly tokenInfo: TokenInfo

    constructor(tokenInfo: TokenInfo, list?: TokenList) {
        this.tokenInfo = tokenInfo
        this.list = list
    }

    public get wrapped(): Token {
        return this
    }

    private _checkSumAddress: string | null = null

    public get address(): string {
        if(this._checkSumAddress) return this._checkSumAddress
        const checkSumAddress = isAddress(this.tokenInfo.address)
        if(!checkSumAddress) throw Error(`${this.tokenInfo.address}: Address is not a valid Ethereum address`)

        return (this._checkSumAddress = getAddress(this.tokenInfo.address))
    }

    public get chainId(): number {
        return this.tokenInfo.chainId
    }

    public get decimals(): number {
        return this.tokenInfo.decimals
    }

    public get symbol(): string {
        return this.tokenInfo.symbol
    }

    public get name(): string {
        return this.tokenInfo.name
    }

    public get logoURI(): string {
        return this.tokenInfo.logoURI
    }

    public equals(other: Currency): boolean {
        return other.isToken && this.chainId === other.chainId && this.address === other.address
    }

    public sortsBefore(other: Currency): boolean {
        if(this.equals(other)) throw Error(`Tokens should not be equal`)
        if(!other.isToken) throw Error(`Other is not type Token`)
        return this.address.toLowerCase() < other.address.toLowerCase()
    }
}