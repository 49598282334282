//COMPONENTS
import { TextWrapper } from '../../theme/components'
import Row from '../Row'
import { AutoColumn } from '../Column'

//LIBS
import { useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components/macro'
import JSBI from 'jsbi'

//UNISWAPV3
import { Position } from '@uniswap/v3-sdk'
//CORE
import { CurrencyAmount, Currency, Token, Price } from '@uniswap/sdk-core'

//HOOKS
import useTheGraphUSDCPrice from '../../hooks/useTheGraphUSDCPrice'

//REACT
import { useMemo, useRef } from 'react'

//INTERFACES
import { PoolHourData } from '../../interfaces'

//CONSTANTS
import { FixedPoint128 } from '../../constants/fixedPointMath'

export default function FeesResult({ 
    data, 
    currencyA, 
    currencyB, 
    isLoading, 
    isError, 
    sorted, 
    positionOut, 
    liquidityIn, 
    block, 
    onNewFiatValueOfFees, 
    chainId
}: {
    data: PoolHourData[] | undefined
    isLoading: boolean
    isError: boolean
    sorted: boolean | undefined
    ticks: [number | undefined, number | undefined]
    currencyA: Currency
    currencyB: Currency | undefined
    positionOut: Position | undefined
    liquidityIn: string | undefined
    block: number | undefined
    onNewFiatValueOfFees: (value: CurrencyAmount<Token>) => void
    chainId: number
} ) {
    const theme = useContext(ThemeContext)

    const priceAmount0InUSDC = useTheGraphUSDCPrice({ currencyAmount: positionOut?.amount0, block, chainId })
    const priceAmount1InUSDC = useTheGraphUSDCPrice({ currencyAmount: positionOut?.amount1, block, chainId })

    const [feeCurrencyAmount0, feeCurrencyAmount1] = useMemo(() => {  
        
        if(!data || !data.length || !positionOut || !liquidityIn) return [undefined, undefined]

        let provisional: PoolHourData[] = []
        const inRangeItems: PoolHourData[][] =[]

        data.forEach((item: PoolHourData, index: number) => {

            if(positionOut.tickLower < item.tick && item.tick < positionOut.tickUpper) {
                provisional.push(item)
                if(index === data.length - 1) inRangeItems.push(provisional)
            } else {
                if(provisional.length > 0) {
                    inRangeItems.push(provisional)
                    provisional = []
                }
            }
        })

        let feeGrowthGlobal0X128: JSBI = JSBI.BigInt(0)
        let feeGrowthGlobal1X128: JSBI = JSBI.BigInt(0)

        for (let i = 0; i < inRangeItems.length; i++) {
            const feeGrowthGlobal0XToBeAdded = JSBI.subtract(
              JSBI.BigInt(inRangeItems[i][inRangeItems[i].length - 1].feeGrowthGlobal0X128),
              JSBI.BigInt(inRangeItems[i][0].feeGrowthGlobal0X128)
            )
            feeGrowthGlobal0X128 = JSBI.add(feeGrowthGlobal0X128, feeGrowthGlobal0XToBeAdded)
      
            const feeGrowthGlobal1XToBeAdded = JSBI.subtract(
              JSBI.BigInt(inRangeItems[i][inRangeItems[i].length - 1].feeGrowthGlobal1X128),
              JSBI.BigInt(inRangeItems[i][0].feeGrowthGlobal1X128)
            )
            feeGrowthGlobal1X128 = JSBI.add(feeGrowthGlobal1X128, feeGrowthGlobal1XToBeAdded)
        }

        const feeGrowthGlobal0 = JSBI.divide(JSBI.multiply(feeGrowthGlobal0X128, JSBI.BigInt(liquidityIn)), FixedPoint128)
        const feeGrowthGlobal1 = JSBI.divide(JSBI.multiply(feeGrowthGlobal1X128, JSBI.BigInt(liquidityIn)), FixedPoint128)


        const feeCurrencyAmount0 = CurrencyAmount.fromRawAmount(positionOut.pool.token0, feeGrowthGlobal0)
        const feeCurrencyAmount1 = CurrencyAmount.fromRawAmount(positionOut.pool.token1, feeGrowthGlobal1)

        return [feeCurrencyAmount0, feeCurrencyAmount1]

    }, [data, liquidityIn, positionOut])

    const fiatValueOfFees = useMemo(() => {
         if (!feeCurrencyAmount0 || !feeCurrencyAmount1 || !priceAmount0InUSDC || !priceAmount1InUSDC) return null
        
         return priceAmount0InUSDC.quote(feeCurrencyAmount0).add(priceAmount1InUSDC.quote(feeCurrencyAmount1))
     }, [feeCurrencyAmount0, feeCurrencyAmount1, priceAmount0InUSDC, priceAmount1InUSDC])



     useEffect(() => {
        if(!fiatValueOfFees) return
         onNewFiatValueOfFees(fiatValueOfFees)
     }, [fiatValueOfFees, onNewFiatValueOfFees])

    return (
        <Row paddingX={4} paddingY={1} backgroundColor={theme.blue} borderRadius={'20px'}>
            <AutoColumn>
                <TextWrapper fontSize={1} color={theme.lightBlue}>
                    EARNED FEES:
                </TextWrapper>
                <TextWrapper fontSize={1}>
                    Fees USD: ${fiatValueOfFees?.toSignificant(4)}
                </TextWrapper>
                <TextWrapper fontSize={1}>
                    {currencyA.symbol}: {(feeCurrencyAmount1 && feeCurrencyAmount0) ? sorted ? feeCurrencyAmount0.toSignificant(6) : feeCurrencyAmount1.toSignificant(6) : '0.0'}
                </TextWrapper>
                <TextWrapper fontSize={1}>
                    {currencyB ? currencyB.symbol + ': ' : 'Select quote token'} {(feeCurrencyAmount1 && feeCurrencyAmount0) ? sorted ? feeCurrencyAmount1.toSignificant(6) : feeCurrencyAmount0.toSignificant(6) : currencyB ? '0.0' : ''}
                </TextWrapper>
            </AutoColumn>
        </Row>
    )
}