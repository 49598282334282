import styled from 'styled-components/macro'
import Row, { RowBetween } from '../Row'
import DefiMathLogo from '../../assets/DefiMath.png'
import NetworkSelector from './NetworkSelector'
import Menu from '../Menu'
import { useState } from 'react'
import { SupportedChainId } from '../../constants/chains'


const HeaderFrame = styled.div`
    background: linear-gradient(180deg, rgba(1,173,233,1) 0%, rgba(0,0,64,1) 100%); 
    width: 100%;
`;

const StyledWrapper = styled.div`
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 10px;
    width: 100%;
    color: white;
    max-width: 1260px;
    margin: auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr 1fr;
        padding: 0.6rem 0.8rem;
        position: relative;
    `}
`

const DefiMathIcon = styled.div`
    transition: transform 0.3s ease;
    :hover {
        transform: rotate(-2deg);
    }
`

const HeaderActions = styled(Row)`
    gap: 4px;
    align-items: stretch;
`


export enum HeaderModals {
    NETWORK,
    MENU,
    NONE
}


export default function Header({
    activeChainId,
    onActiveChainIdChange
}: {
    activeChainId: SupportedChainId,
    onActiveChainIdChange: (chainId: SupportedChainId) => void
}) {
    const [activeHeaderModal, setActiveHeaderModal] = useState(HeaderModals.NONE)

    return (
        <HeaderFrame>
            <StyledWrapper>
            <a href=".">
                    <DefiMathIcon>
                        <img src={DefiMathLogo} alt={'DefiMath logo'}></img>
                    </DefiMathIcon>
                </a>
                <Row>
                    <HeaderActions justify={'end'} padding={'0 0 12px 0'}>
                        <NetworkSelector 
                            onHeaderModalChange={setActiveHeaderModal} 
                            activeHeaderModal={activeHeaderModal} 
                            onActiveChainIdChange={onActiveChainIdChange}
                            activeChainId={activeChainId}
                        />
                        <Menu manageModal={setActiveHeaderModal} active={activeHeaderModal}></Menu>
                    </HeaderActions>
                </Row>
            </StyledWrapper>
        </HeaderFrame>
    )
}
