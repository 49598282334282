import { axisBottom, AxisScale, select } from 'd3'
import { useRef, useEffect } from 'react'
import styled from 'styled-components/macro'


interface AxisbottomProps {
    scale: AxisScale<Date>, 
    location: number, 
}


export default function AxisBottom({ scale, location }: AxisbottomProps ) {
    const axisRef = useRef<SVGGElement>(null)

    useEffect(() => {
        if(!axisRef.current) return
        const xAxis = axisBottom(scale).ticks(5).tickSizeOuter(0)

        const selection = select(axisRef.current)
        selection.call(xAxis).call((g) => g.select('.domain').remove())
    }, [scale])

    return(<g transform={`translate(${[0, location].join(',')})`} ref={axisRef} opacity={0.7}/>)
}
