
//Libraries
import { ZoomTransform, D3BrushEvent, BrushBehavior, select, brushX } from 'd3'
import { ThemeContext } from 'styled-components/macro'

//React
import { useRef, useContext, useEffect, RefObject, useState } from 'react'


export default function Brush({ 
    group, 
    widthExtent, 
    heightExtent, 
    brushExtent, 
    handleBrushMove, 
    transform 
}: {
    group: RefObject<SVGGElement>
    widthExtent: number
    heightExtent: number
    brushExtent: [number, number] | null | undefined
    handleBrushMove: (event: D3BrushEvent<unknown>) => void
    transform?: ZoomTransform
} ) {
    /* State for displaying ChartPriceHandlers once the brush behavioor has selected a group 
        otherwise the brush will overlay the custom ChartPriceHandlers */
    const [brushActive, setBrushActive] = useState(false)   

    const brush = useRef<BrushBehavior<unknown>>(
        brushX()
            .extent([[0, 0], [widthExtent, heightExtent]])
            .on('start brush end', handleBrushMove).handleSize(30)
    )

    useEffect(() => {
        if(!group.current || !brush.current) return
        brush.current(select(group.current))
        if(!brushActive) setBrushActive(true)
    }, [brushActive, group])


    useEffect(() => {
        if(!group.current || !brush.current || !brushExtent) return
        brush.current.move(select(group.current), brushExtent)
    }, [brushExtent, group])

    return(
        (brushExtent && brushActive) ? (
            <ChartPriceHandlers cords={brushExtent} height={heightExtent} width={widthExtent}/>
        ) : null
    )
}

const pathHandler = (cords: [number, number], height: number, width: number, id: 'a' | 'b') => {
    let handlerPath;
    let handlerDetails;
    const cord = id === 'a' ? cords[0] : cords[1]

    if((id === 'a' && cord <= 5) || (id === 'b' && !(cord >= width - 5))) {
        handlerPath = [
            `M ${cord} ${height - 20}`,  
            `h12`,
            `q2,0 2,2`,
            `v16`,
            `q0,2 -2,2`, 
            `h-12`
        ].join(' ')
        handlerDetails = [
            `M ${cord + 4} ${height - 16}`,
            `v0,12`, 
            `M ${cord + 8} ${height - 16}`, 
            `v0,12`
        ].join(' ')
    } else {
        handlerPath = [
            `M ${cord} ${height - 20}`,
            `h-12`, 
            `q-2,0 -2,2`, 
            `v16`, 
            `q0,2 2,2`, 
            `h12`
        ].join(' ')
        handlerDetails = [
            `M ${cord - 4} ${height - 16}`, 
            `v0,12`, 
            `M ${cord - 8} ${height - 16}`, 
            `v0,12`
        ].join(' ')
    }

    return [handlerPath, handlerDetails]
}

function ChartPriceHandlers({ 
    cords, 
    height,
    width
}: {
    cords: [number, number]
    height: number
    width: number
} ) {
    const theme = useContext(ThemeContext)

    return (
        <g>
           <path
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={`M ${cords[0]} ${0} v ${height}`} 
                stroke={theme.lightBlue}
                strokeWidth={1.5} 
            />
            <path
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={pathHandler(cords, height, width, 'a')[0]} 
                stroke={theme.lightBlue}
                strokeWidth="1.5" 
                fill={theme.lightBlue} 
            />
            <path 
                d={pathHandler(cords, height, width, 'a')[1]}
                stroke={theme.white}
                strokeWidth="1"
            />
            <path
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={`M ${cords[1]} ${0} v ${height}`} 
                stroke={theme.lightBlue}
                strokeWidth={1.5} 
            />
            <path
                style={{cursor: 'ns-resize', pointerEvents: 'none'}} 
                d={pathHandler(cords, height, width, 'b')[0]} 
                stroke={theme.lightBlue}
                strokeWidth="1.5" 
                fill={theme.lightBlue} 
            />
            <path 
                d={pathHandler(cords, height, width, 'b')[1]}
                stroke={theme.white}
                strokeWidth="1"
            />
        </g>
    )
}

 
