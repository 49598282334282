import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { createMulticall } from '@uniswap/redux-multicall'

export const multicall = createMulticall()

const reducers = combineReducers({
  [multicall.reducerPath]: multicall.reducer
})

const store = configureStore({
  reducer: reducers
})

export default store

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch

