import { useEffect, useState } from 'react'
import { FetchResult } from '../interfaces'

export default function useFetch(url: string): FetchResult {
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isError, setIsError] = useState<boolean>()
    const [data, setData] = useState()

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            try {
                const response = await fetch(url)
                if(!response.ok) {
                    const error = response.statusText;
                    return Promise.reject(error);
                }
                const datas = await response.json()
                setData(datas)
            } catch(err) {
                console.log(`Error fetching ${url}: `, err)
                setIsError(Boolean(err))
            } finally {                
                setIsLoading(false)
            }
        }
        fetchData()
    }, [url])

    return {
        data: data,
        loading: isLoading,
        error: isError
    }
}


