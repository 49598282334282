import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { useEffect, useMemo } from 'react'
import { MULTICALL_L1_ADDRESS, QUOTER_L1_ADDRESS } from '../constants/addresses'
import { abi as MULTICALL_ABI } from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import { abi as QUOTER_ABI } from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'


export function useMulticallContract() {
    const { library } = useWeb3React()
    return useMemo(() => {
        return new Contract(MULTICALL_L1_ADDRESS, MULTICALL_ABI, library)
    }, [library])
}


export function useQuoterContract() {
    const { library } = useWeb3React()
    return useMemo(() => {
        return new Contract(QUOTER_L1_ADDRESS, QUOTER_ABI, library)
    }, [library])
}


