import { getNearestUsableTick, feeTierToTickSpacing } from '../../utils/V3Utils'
import { getDomain, isTouch, accessor } from '../../utils/ChartUtils'
import { compareArrays, compareScaleds } from '../../utils/ChartUtils'

//Interfaces
import { FormattedTick } from '../../interfaces'

//Libraries
import * as d3Scales from 'd3-scale'
import { ZoomTransform, area, D3BrushEvent, curveStepAfter, BrushBehavior, select, brushX, filter } from 'd3'
import styled, { ThemeContext } from 'styled-components/macro'

//React
import { useMemo, Dispatch, MouseEvent, useRef, ReactNode, useContext, useEffect, useState, forwardRef, useCallback } from 'react'

//Uniswap
import { Ether, Price, CurrencyAmount } from '@uniswap/sdk-core'

//Layout
import { AutoColumn, ColumnCenter } from '../Column'
import Row from '../Row'

//Components
import { TextWrapper } from '../../theme/components'
import Zoom from '../Zoom'
import SVGSurface from '../SVGSurface'
import Brush from './Brush'

//Chart
import AxisBottom from './XAxis'



//HOOKS
import usePrevious from '../../hooks/usePrevious'


const StyledLine = styled.line`
    stroke-width: 2;
    stroke: ${({ theme }) => theme.green};
`

export const ZoomOverlay = styled.rect`
    fill: transparent;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
`

interface LiquidityChartProps {
    data: FormattedTick[]
    dimensions: { width: number | undefined; height: number | undefined }
    innerDimensions:{ innerWidth: number; innerHeight: number}
    margin: { top: number; right: number; bottom: number; left: number }
    sorted?: boolean
    ticks: [number, number] | null
    currentTick: number
    onChosenTicks: (ticks: [number, number] | null) => void
    feeTier: number
    transform: ZoomTransform | undefined
}

const LiquidityChart = forwardRef<SVGRectElement, LiquidityChartProps>(({
     data, 
     dimensions: { width, height },
     innerDimensions: { innerWidth, innerHeight },
     margin, 
     sorted,
     ticks,
     currentTick,
     onChosenTicks,
     feeTier,
     transform
    }, ref ) => {
    const theme = useContext(ThemeContext)

    const [brushExtent, setBrushExtent] = useState<[number, number] | null | undefined>()
    const prevTicks = useRef<[number, number] | null>()
    const prevExtent = useRef<[number, number] | null>()

    const node = useRef<SVGGElement>(null)

    const liquidities = useMemo(() => data.map((item: FormattedTick) => ({ ...item, liquidity: parseFloat(item['liquidity'].toString())})), [data])

    const { XScale, YScale } = useMemo(() => {
        const scales = {
            XScale: d3Scales
                .scaleLinear()
                .domain(getDomain(liquidities, 'tickIdx'))
                .range([0, innerWidth]),
            YScale: d3Scales
                .scaleLinear()
                .domain([0, getDomain(liquidities, 'liquidity')[1]])
                .range([innerHeight, 0])
        }

        if(transform) {
            const newScale = transform.rescaleX(scales.XScale)
            scales.XScale.domain(newScale.domain())
        }

        if(!sorted) scales.XScale.domain().reverse()

        return scales
    }, [innerHeight, innerWidth, sorted, liquidities, transform])


    /* Set brush extent from ticks prop which is initially defined from the PriceChart component */
    useEffect(() => {
        if(!ticks) return 

        if(!prevTicks.current || (prevTicks.current && !compareArrays(prevTicks.current, ticks))) {
            const ticksXScaled = ticks.map(XScale) as [number, number]
            console.log('hey')
            setBrushExtent(ticksXScaled)
            prevExtent.current = ticksXScaled
        }

    }, [XScale, innerWidth, prevTicks, ticks])


    const handleBrushMove = useCallback((event: D3BrushEvent<unknown>) => {
        const { selection } = event

        if(!selection || (selection && selection[0] === selection[1])) {
            setBrushExtent(null); prevExtent.current = null; 
            return
        }

        else if(!prevExtent.current || !compareArrays(selection as [number, number], prevExtent.current)) {

            setBrushExtent(selection as [number, number])
            prevExtent.current = selection as [number, number]
        }        
    }, [])

    useEffect(() => {
        if(!brushExtent) return
        // const newTicks = (brushExtent as [number, number])
        //                     .map(XScale.invert)
        //                     .map(tick => getNearestUsableTick(tick, feeTierToTickSpacing(feeTier)))
        //                     .filter((tick): tick is number => !!tick)
                
        // if(newTicks.length !== 2) return    
        
        // if(!prevTicks.current || !compareArrays(prevTicks.current, newTicks)) {
        //     onChosenTicks(newTicks as [number, number])
        //     prevTicks.current = newTicks as [number, number]
        // }
    }, [XScale.invert, brushExtent, feeTier, onChosenTicks])

    const pathArea = useMemo(() => {
        return area<{ liquidity: number; tickIdx: Number }>()
            .curve(curveStepAfter)
            .x(d => XScale(accessor(d, 'tickIdx')))
            .y0(YScale(innerHeight))
            .y1(d => YScale(accessor(d, 'liquidity')))(liquidities)
    }, [XScale, YScale, innerHeight, liquidities])


    return (
        <SVGSurface chart={'Price'} width={width} height={height}>
            <defs>
                <clipPath id={'clip'}>
                    <rect x={0} y={0} width={innerWidth} height={innerHeight}/>
                </clipPath>
            </defs>
            <g transform={`translate(${[margin.left, margin.top].join(',')})`}>
                <AxisBottom scale={XScale} position={innerHeight}/>
                <ZoomOverlay x={0} y={0} width={innerWidth} height={height} ref={ref}/>
                <g clipPath={'url(#clip)'} ref={node}>
                    <path d={pathArea ? pathArea : undefined} fill={theme.pink}/>
                    <StyledLine x1={XScale(currentTick)} y1='0' x2={XScale(currentTick)} y2={innerHeight}/>
                    <Brush 
                        group={node} 
                        widthExtent={innerWidth} 
                        heightExtent={innerHeight} 
                        brushExtent={brushExtent} 
                        handleBrushMove={handleBrushMove} 
                        transform={transform}
                    />
                </g>
            </g>
        </SVGSurface>
    )
})

export default LiquidityChart

