import { useState } from 'react'
import Calendar from '../Calendar/index'
import { isBefore, min } from 'date-fns'
import { DatesInterface } from '../../../interfaces'

type NewSelection = {
    range: DatesInterface,
    nextFocusRange: number
}

export default function DatesRange({
    range, 
    onRangeChange, 
    maxDate = new Date(),
    minDate = new Date(2021, 4, 6),
    onHandleRenderCalendar,
    renderCalendar
}: {
    range: DatesInterface
    onRangeChange: (arg0: DatesInterface) => void
    moveRangeOnFirstSelection?: boolean
    retainEndDateOnFirstSelection?: boolean
    maxDate?: Date
    minDate?: Date
    onHandleRenderCalendar: (render: boolean) => void
    renderCalendar: boolean
} ) {
    const [focusedDate, setFocusedDate] = useState(1)
    const [localDate, setLocalDate] = useState()
    const [preview, setPreview] = useState<DatesInterface | null>(null)


    const calcNewSelection = (value: Date | DatesInterface): NewSelection => {
        let { startDate } = range
        let nextFocusRange
        let endDate
         if (focusedDate === 0) {
            startDate = value as Date
            endDate = null
            nextFocusRange = 1
        } else {
            endDate = value as Date
        }

        if (startDate && endDate && isBefore(endDate, startDate)) {
          ;[startDate, endDate] = [endDate, startDate]
        }

        if (!nextFocusRange) {
            nextFocusRange = 0
        }

        return {
            range: { startDate, endDate, sliderDate: null, today: new Date() },
            nextFocusRange: nextFocusRange
        }
    }

    const handleRangeFocusChange = (focusToDate: number) => {
        if (focusedDate !== focusToDate) {
            setFocusedDate(focusToDate)
        }
    }

    const setSelection = (value: Date | DatesInterface) => {
        const newSelection = calcNewSelection(value)
        onRangeChange({
            ...newSelection.range,
          },
        )
        setFocusedDate(newSelection.nextFocusRange)
        setPreview(null)
    }

    const updatePreview = (val?: NewSelection | null) => {
        if (!val) {
            setPreview(null)
            return
        }
        setPreview(val.range)
    }

    return (
        <Calendar 
            focusedDate={focusedDate} 
            range={range} 
            updateRange={(val: DatesInterface) => setSelection(val)} 
            onChange={setSelection}
            onRangeFocusChange={handleRangeFocusChange}
            maxDate={maxDate}
            minDate={minDate}
            onPreviewChange={value => {
                updatePreview(value ? calcNewSelection(value) : null)
            }}
            preview={preview}
            onHandleRenderCalendar={onHandleRenderCalendar}
            renderCalendar={renderCalendar}
        />
    )
}


