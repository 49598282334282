import subMonths from 'date-fns/subMonths'
import Decimal from 'decimal.js-light'
import { TouchEvent, MouseEvent } from 'react'
import { bisector, ScaleLinear } from 'd3'
import { PoolHourData } from '../../interfaces'

export const isTouch = (e: TouchEvent<SVGElement> | MouseEvent<SVGElement>): e is TouchEvent<SVGElement> =>
  (e as TouchEvent<SVGElement>).changedTouches && !!(e as TouchEvent<SVGElement>).changedTouches.length

export function getDomain(arr: Record<string, any>[], key: string) {
    if(!arr || !arr.length || !key) return [Infinity, -Infinity]
    const items = arr.map(item => accessor(item, key)).filter(isNumber)
    return items.length ? [Math.min(...items), Math.max(...items)] : [Infinity, -Infinity]
}

export function accessor<Type, Key extends keyof Type>(obj: Type, key: Key) {
    return obj[key]
}

export function isNumber(item: any): item is number {
    return typeof item === 'number' && isFinite(item)
}

export function getDomainOfDates(arr: Record<string, any>[], key: string): [Date, Date] {
    if(!arr || !arr.length || !key) return [subMonths(new Date(), 1), new Date()]
    const dates = arr.map(item => accessor(item, key)).filter((item) => item instanceof Date)
    const sortedDates = dates.sort((a, b) => a - b)
    return [sortedDates[0], sortedDates[sortedDates.length - 1]]
}

export function bisectDate(data: PoolHourData[], date: Date) {
  const bisectorCeter =  bisector(function(d : PoolHourData) { return d.periodStartUnix; }).center;
  return bisectorCeter(data, date)
}

export function compareArrays(a: number[] | undefined | null, b: number[] | undefined | null) {
  if(!a || !b) return false
  if(a.length !== b.length) return false
  for(let i = 0; i < a.length; i++) {
      if(a[i] !== b[i]) return false
  }
  return true
}

export function compareScaleds(a: [number, number], b: [number, number], scale: ScaleLinear<number, number>): boolean {
  // normalize pixels to 1 decimals
  const aNorm = a.map((x) => scale(x).toFixed(1))
  const bNorm = b.map((x) => scale(x).toFixed(1))
  return aNorm.every((v, i) => v === bNorm[i])
}


function getValidInterval([min, max]: number[]) {
    let [validMin, validMax] = [min, max]
    if (min > max) {
      [validMin, validMax] = [max, min]
    }
    return [validMin, validMax]
}

function getFormatStep(roughStep: Decimal, allowDecimals: boolean, correctionFactor: number) {
    if (roughStep.lte(0)) { return new Decimal(0) }

    const digitCount = getDigitCount(roughStep.toNumber())
    const digitCountValue = new Decimal(10).pow(digitCount)

    const stepRatio = roughStep.div(digitCountValue)
    const stepRatioScale = digitCount !== 1 ? 0.05 : 0.1

    const amendStepRatio = new Decimal(
        Math.ceil(stepRatio.div(stepRatioScale).toNumber()),
      ).add(correctionFactor).mul(stepRatioScale)
    
    const formatStep = amendStepRatio.mul(digitCountValue)

    return allowDecimals ? formatStep : new Decimal(Math.ceil(formatStep.toNumber()))
}

function calculateStep(min: number, max: number, tickCount: number, allowDecimals: boolean, correctionFactor = 0): any {
    if (!Number.isFinite((max - min) / (tickCount - 1))) {
        return {
          step: new Decimal(0),
          tickMin: new Decimal(0),
          tickMax: new Decimal(0),
        }
    }
    const step = getFormatStep(new Decimal(max).sub(min).div(tickCount - 1), allowDecimals, correctionFactor)
    
    let middle;

    if (min <= 0 && max >= 0) {
      middle = new Decimal(0)
    } else {
      // calculate the middle value
      middle = new Decimal(min).add(max).div(2)
      // minus modulo value
      middle = middle.sub(new Decimal(middle).mod(step))
    }

    let belowCount = Math.ceil(middle.sub(min).div(step).toNumber())
    let upCount = Math.ceil(new Decimal(max).sub(middle).div(step)
    .toNumber())

    const scaleCount = belowCount + upCount + 1

    if (scaleCount > tickCount) {
        // When more ticks need to cover the interval, step should be bigger.
        return calculateStep(min, max, tickCount, allowDecimals, correctionFactor + 1)
    } if(scaleCount < tickCount) {
        upCount = max > 0 ? upCount + (tickCount - scaleCount) : upCount
        belowCount = max > 0 ? belowCount : belowCount + (tickCount - scaleCount)
    }

    return {
        step,
        tickMin: middle.sub(new Decimal(belowCount).mul(step)),
        tickMax: middle.add(new Decimal(upCount).mul(step)),
    }
}

function rangeStep(start: number, end: number, step: number) {
    let num = new Decimal(start);
    let i = 0;
    const result = [];
  
    // magic number to prevent infinite loop
    while (num.lt(end) && i < 100000) {
      result.push(num.toNumber());
  
      num = num.add(step);
      i++;
    }
  
    return result;
}

export function getNiceTicksValues([min, max]: number[], tickCount = 5, allowDecimals: boolean) {
    const count = Math.max(tickCount, 2)
    const [cormin, cormax] = getValidInterval([min, max])

    if (cormin === -Infinity || cormax === Infinity) {
        const values = cormax === Infinity
          ? [cormin, ...Array.from(Array(count - 1).keys()).map(() => Infinity)]
          : [...Array.from(Array(count - 1).keys()).map(() => -Infinity), cormax];
    
        return min > max ? values.reverse() : values
    }

    if (cormin === cormax) {
        return getTickOfSingleValue(cormin, count, allowDecimals)
    }
    const { step, tickMin, tickMax } = calculateStep(cormin, cormax, count, allowDecimals)

    const values = rangeStep(tickMin, tickMax.add(new Decimal(0.1).mul(step)), step)

    return min > max ? values.reverse() : values
}


const identity = (i: () => any) => i

const curry0 = (fn: any) => function _curried(...args: any[]) {
    if (args.length === 0) {
      return _curried;
    }
  
    return fn(...args)
  }
  
const curryN = (n: number, fn: any) => {
    if (n === 1) {
        return fn;
    }

    return curry0((...args: any[]) => {
        const argsLength = args.length

        if (argsLength >= n) {
        return fn(...args)
        }

        return curryN(n - argsLength, curry0((...restArgs: any[]) => {  
        return fn(...args, ...restArgs);
        }))
    })
}

export const curry = (fn: any) => curryN(fn.length, fn);

export const map = curry((fn: any, arr: any) => {
    if (Array.isArray(arr)) {
      return arr.map(fn)
    }
    return Object.keys(arr).map((key) => arr[key]).map(fn)
})

export const range = (begin: number, end: number) => {
    const arr = []
    for (let i = begin; i < end; ++i) {
      arr[i - begin] = i
    }
    return arr
}

export const compose = (...args: any[]) => {
    if (!args.length) {
      return identity;
    }
  
    const fns = args.reverse();
    // first function can receive multiply arguments
    const firstFn = fns[0];
    const tailsFn = fns.slice(1);
  
    return (...composeArgs: any[]) => tailsFn.reduce((res, fn) => fn(res),
      firstFn(...composeArgs));
  };

export function getDigitCount(value: number) {
    let result
  
    if (value === 0) {
      result = 1
    } else {
      result = Math.floor(new Decimal(value).abs().log(10).toNumber()) + 1
    }
    return result
}

export function getTickOfSingleValue(value: number, tickCount: number, allowDecimals: boolean) {
    let step: number | Decimal = 1
    let middle = new Decimal(value)
  
    if (!middle.isint() && allowDecimals) {
      const absVal = Math.abs(value)
  
      if (absVal < 1) {

        step = new Decimal(10).pow(getDigitCount(value) - 1)
        middle = new Decimal(Math.floor(middle.div(step).toNumber())).mul(step)

      } else if (absVal > 1) {
        middle = new Decimal(Math.floor(value))
      }
    } else if (value === 0) {
      middle = new Decimal(Math.floor((tickCount - 1) / 2))
    } else if (!allowDecimals) {
      middle = new Decimal(Math.floor(value))
    }
  
    const middleIndex = Math.floor((tickCount - 1) / 2)

    const fn = compose(
      map((n: number) => middle.add(new Decimal(n - middleIndex).mul(step)).toNumber()),
      range,
    )
    return fn(0, tickCount)
  }