import { MIN_TICK, MAX_TICK } from '../../constants/v3'


export function getNearestUsableTick(tick: number, tickSpacing: number): number | undefined {
    if(!Number.isInteger(tick) && !Number.isInteger(tickSpacing)) return
    if(!(tick >= MIN_TICK) && !(tick <= MAX_TICK)) return
    const rounded = Math.round(tick / tickSpacing) * tickSpacing
    if (rounded < MIN_TICK) return rounded + tickSpacing
    else if (rounded > MAX_TICK) return rounded - tickSpacing
    else return rounded
}

export function feeTierToTickSpacing(feeTier: number): number {
    if(feeTier === 100) {
        return 1
    } else if(feeTier === 500) {
      return 10
    } else if (feeTier === 3000) {
      return 60
    } else if(feeTier === 10000) {
      return 200
    } else {
        throw Error(`${feeTier} is no valid`)
    }
}