import { blocksClient } from '../apollo'
import { useQuery, gql, ApolloError } from "@apollo/client"
import { DatesInterface, BlockData } from '../interfaces'
import { BlockType} from '../constants/enums'
import { getUnixTime } from 'date-fns'
import { useMemo } from 'react'
import useGetClients from './useGetClients'

const GETBLOCKS = gql`
    query getBlocks($unixStartDate: BigInt!, $unixEndDate: BigInt!) {
        IN: blocks(first: 1, orderBy: timestamp, orderDirection: asc, where: { timestamp_gte: $unixStartDate }) {
            number
            timestamp
        }
        OUT: blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: $unixEndDate }) {
            number
            timestamp
        }
    }
`

const getLastMinedBlockNumber = gql`
    query getBlockNumber {
        blocks(first: 1, orderDirection: desc, orderBy: number) {
            number
        }
    }
`

export function useLastMinedBlockNumber() {
    const { loading, error, data } = useQuery(getLastMinedBlockNumber, {
        client: blocksClient,
        fetchPolicy: "no-cache",
    })


    return {
        data,
        error,
        loading
    }
}


export function useGetBlockNumbers(range: DatesInterface, chainId: number): {
    data: { [block in BlockType]?: BlockData } ,
    error: boolean,
    loading: boolean
} {
    const { blockClient } = useGetClients(chainId)
    const { startDate, endDate } = range

    const [unixStartDate, unixEndDate] = useMemo(() => {
        if(!startDate || !endDate) return [undefined, undefined]

        return [
            getUnixTime(startDate), 
            getUnixTime(endDate), 
        ]
    }, [endDate, startDate])

    const { data, loading, error } = useQuery(GETBLOCKS, {
        client: blockClient,
        errorPolicy: 'all',
        skip: !unixStartDate || !unixEndDate,
        variables: {
            unixStartDate,
            unixEndDate        
        }
    })

    return useMemo(() => {
        return {
            data: Object.keys(data || {}).reduce<{ [block in BlockType]?: BlockData }>((prev, curr) => {
                return {
                    ...prev,
                    [curr] : {
                        number: parseInt(data[curr][0].number),
                        timestamp: parseInt(data[curr][0].timestamp)
                    }
                }
            }, {}),
            loading,
            error: Boolean(error)
        }
    }, [data, error, loading])
}

const GETSINGLEBLOCK = gql`
query getBlocks($unixDate: BigInt!) {
    SLIDER: blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: $unixDate }) {
        number
        timestamp
    }
}
`

export function useGetSingleBlockNumber(date: Date | null, blocks: { [block in BlockType]?: BlockData }, chainId: number) {
    const { blockClient } = useGetClients(chainId)

    const unixDate = useMemo(() => {
        if(!date) return 
        return getUnixTime(date)
    }, [date])


    const { data, loading, error } = useQuery(GETSINGLEBLOCK, {
        client: blockClient,
        skip: !unixDate,
        variables: {
            unixDate
        }
    })


    return useMemo(() => {
        return {
            data: Object.keys(data || {}).reduce<{ [key in BlockType]?: BlockData }>((prev, curr) => {
                return {
                    ...prev,
                    [curr] : {
                        number: parseInt(data[curr][0].number),
                        timestamp: parseInt(data[curr][0].timestamp)
                    },
                }
            }, blocks ),
            loading,
            error: Boolean(error)
        }
    }, [blocks, data, error, loading])
}
