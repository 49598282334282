import {
    ApolloClient,
    InMemoryCache
  } from "@apollo/client";

  export const client = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });


  export const blocksClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
  
  // export const clients = new ApolloClient({
  //   uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-subgraph',
  //   cache: new InMemoryCache(
  //     {
  //     typePolicies: {
  //       Token: {
  //         // Singleton types that have no identifying field can use an empty
  //         // array for their keyFields.
  //         keyFields: false,
  //       },
  //       Pool: {
  //         // Singleton types that have no identifying field can use an empty
  //         // array for their keyFields.
  //         keyFields: false,
  //       },
  //     },
  //   }
  //   ),
  //   defaultOptions: {
  //     watchQuery: {
  //       fetchPolicy: 'no-cache',
  //     },
  //     query: {
  //       fetchPolicy: 'no-cache',
  //       errorPolicy: 'all',
  //     },
  //   },
  // })
  
  export const arbitrumClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-dev',
    cache: new InMemoryCache({
      typePolicies: {
        Token: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
        Pool: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  })
  
  export const arbitrumBlockClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks',
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  })
  
  export const optimismClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',
    cache: new InMemoryCache({
      typePolicies: {
        Token: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
        Pool: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
  
  export const optimismBlockClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uni-testing-subgraph',
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  })
  
  export const polygonClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
    cache: new InMemoryCache({
      typePolicies: {
        Token: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
        Pool: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
  
  export const polygonBlockClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/polygon-blocks',
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  })