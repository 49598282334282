import { PriceTooltip } from '../../interfaces'
import Column, { AutoColumn } from '../Column'
import Row, { RowFixed } from '../Row'
import { Pool } from '@uniswap/v3-sdk'
import { TextWrapper } from '../../theme/components'

export default function Tooltip({ 
    pool, 
    loading, 
    error, 
    sorted, 
    tooltip 
}: {
    pool: Pool | undefined
    sorted: boolean | undefined
    tooltip: PriceTooltip | undefined | null
    loading: boolean
    error: boolean
} ) {

    return (
        <Row>
            <Column>
                { loading ?  <TextWrapper>Loading current pool price</TextWrapper> 
                    : error ? <TextWrapper>Error fetching current pool price</TextWrapper> 
                    : (
                        <Column>
                            <TextWrapper opacity={0.7}>
                                { tooltip ? tooltip.price : (pool && sorted ) ? pool.token0Price.toFixed(4) : (pool && !sorted )? pool.token1Price.toFixed(4) : 'Select quote token' }{' '}
                                { pool ? !sorted ? pool.token0.symbol + ' per ' + pool.token1.symbol : pool.token1.symbol + ' per ' + pool.token0.symbol : '' }
                            </TextWrapper>
                            <TextWrapper opacity={0.7}>
                                { tooltip ? tooltip.date : `${new Date().toDateString()}`}
                            </TextWrapper>
                        </Column>
                    )
                }
            </Column>
        </Row>
    )
}