import Locale, { startOfMonth, endOfMonth, differenceInCalendarMonths, startOfWeek, endOfWeek, differenceInHours } from 'date-fns'
import { DatesInterface } from '../../interfaces'

export function calcFocusDate(
  currentFocusedDate: Date | null, 
  months: number, 
  range: DatesInterface, 
  ) {
  // find primary date according the props
  let targetInterval;
  targetInterval = {
      start: range.startDate,
      end: range.endDate,
    }
  targetInterval.start = startOfMonth(targetInterval.start || new Date())
  targetInterval.end = endOfMonth(targetInterval.end || targetInterval.start)
  const targetDate = targetInterval.start || targetInterval.end || new Date()
  // initial focus
  if (!currentFocusedDate) return targetDate;

  // // just return targetDate for native scrolled calendars
  // if (props.scroll.enabled) return targetDate;
  if (differenceInCalendarMonths(targetInterval.start, targetInterval.end) > months) {
    // don't change focused if new selection in view area
    return currentFocusedDate;
  }
  return targetDate;
}


export function getMonthDisplayRange(date: Date, dateOptions: { locale: Locale }) {
  const startDateOfMonth = startOfMonth(date)
  const endDateOfMonth = endOfMonth(date)
  const startDateOfCalendar = startOfWeek(startDateOfMonth, dateOptions);
  let endDateOfCalendar = endOfWeek(endDateOfMonth, dateOptions);

  return {
    start: startDateOfCalendar,
    end: endDateOfCalendar,
    startDateOfMonth,
    endDateOfMonth,
  }
}


export function getRangeTotalHours(timestampIn: number | undefined, timestampOut: number | undefined) {
  if(!timestampIn || !timestampOut) return
  return differenceInHours(timestampOut * 1000, timestampIn * 1000)
}
