import { Web3Provider } from '@ethersproject/providers'

export default function getLibrary(provider: any) {
    return new Web3Provider(provider,
      typeof provider.chainId === 'number'
    ? provider.chainId
    : typeof provider.chainId === 'string'
    ? parseInt(provider.chainId)
    : 'any'
    )
  }