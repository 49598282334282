import { useQuery, gql, ApolloError } from "@apollo/client";
import { previousDay } from "date-fns";
import JSBI from 'jsbi'
import { UnformattedTick, FormattedTick } from '../interfaces'
import { getNearestUsableTick, feeTierToTickSpacing } from '../utils/V3Utils'
import { MIN_TICK, MAX_TICK } from '../constants/v3'

const TICKSQUERYWITHBLOCK = gql`
    query getTicksInfo($poolAddress: String!, $minTick: BigInt!, $nearestUsableTick: BigInt!, $maxTick: BigInt!, $blockNumber: Int) {
        lowerTicks: ticks(where: { poolAddress: $poolAddress, tickIdx_gte: $minTick, tickIdx_lt: $nearestUsableTick }, first: 1000, block: { number: $blockNumber } ) {
            tickIdx
            liquidityGross
            liquidityNet
        }
        upperTicks: ticks(where: { poolAddress: $poolAddress, tickIdx_gte: $nearestUsableTick, tickIdx_lte: $maxTick }, first: 1000, block: { number: $blockNumber }) {
            tickIdx
            liquidityGross
            liquidityNet
        }
    }
`

const TICKSQUERY = gql`
    query getTicksInfo($poolAddress: String!, $minTick: BigInt!, $nearestUsableTick: BigInt!, $maxTick: BigInt!) {
        lowerTicks: ticks(where: { poolAddress: $poolAddress, tickIdx_gte: $minTick, tickIdx_lt: $nearestUsableTick }, first: 1000 ) {
            tickIdx
            liquidityGross
            liquidityNet
        }
        upperTicks: ticks(where: { poolAddress: $poolAddress, tickIdx_gte: $nearestUsableTick, tickIdx_lte: $maxTick }, first: 1000 ) {
            tickIdx
            liquidityGross
            liquidityNet
        }
    }
`


export default function useFetchTicksInfo({ 
    feeTier, 
    liquidity, 
    poolAddress, 
    tickCenter, 
    blockNumber, 
    defaultTicksToFetch,
}: {
    feeTier: number
    liquidity?: string
    poolAddress?: string
    tickCenter?: number
    defaultTicksToFetch: number | undefined
    blockNumber?: number
} ) : {
    data?: FormattedTick[],
    loading: boolean,
    error: boolean
} {
    const tickSpacing = feeTierToTickSpacing(feeTier)
    
    const nearestUsableTick = tickCenter ? getNearestUsableTick(tickCenter, tickSpacing) : undefined

    const minTickUnchecked = nearestUsableTick  ? nearestUsableTick -  (defaultTicksToFetch ? defaultTicksToFetch *  tickSpacing : 0) : undefined

    const maxTickUnchecked = nearestUsableTick ? nearestUsableTick +  (defaultTicksToFetch ? defaultTicksToFetch *  tickSpacing : 0) : undefined

    const minTick = minTickUnchecked && minTickUnchecked > MIN_TICK ? minTickUnchecked : MIN_TICK

    const maxTick = maxTickUnchecked && maxTickUnchecked < MAX_TICK ? maxTickUnchecked : MAX_TICK

    const { data, loading, error } = useQuery(blockNumber ? TICKSQUERYWITHBLOCK : TICKSQUERY, {
        skip: !minTick || !nearestUsableTick || !maxTick || !defaultTicksToFetch,
        variables: {
            poolAddress,
            minTick,
            nearestUsableTick,
            maxTick,
            blockNumber
        }
    })

    if(!data || loading || error || !liquidity || !nearestUsableTick) return {
        data: undefined,
        loading: loading,
        error: Boolean(error)
    }

    const {lowerTicks, upperTicks} = data

    const formattedData = lowerTicks.concat(upperTicks).reduce((acc: { [key: string]: UnformattedTick }, curr: UnformattedTick) => {
        acc[curr['tickIdx']] = curr
        return acc
    }, {})
    
    const tickCurrentFormatted = {
        tickIdx: nearestUsableTick,
        liquidity: JSBI.BigInt(liquidity),
        liquidityNet: JSBI.BigInt(0),
        liquidityGross: JSBI.BigInt(0),
        isCurrentTick: true
    }

    if(formattedData[nearestUsableTick]) {
        tickCurrentFormatted.liquidityNet = JSBI.BigInt(formattedData[nearestUsableTick].liquidityNet)
        tickCurrentFormatted.liquidityGross = JSBI.BigInt(formattedData[nearestUsableTick].liquidityGross)
    }

    enum dir {
        'desc',
        'asc'
    }

    const formatTicks = (direction: dir, ticksList: UnformattedTick[]) => {
        if(!defaultTicksToFetch) return []

        let previous = tickCurrentFormatted

        const allTicksWithLiquidity = []
    
        for(let i = direction === 0 ? -1 : 1; direction === 0 ? i > -defaultTicksToFetch : i < defaultTicksToFetch; direction === 0 ? i-- : i++) {
            let nextTickIdx = nearestUsableTick + (tickSpacing * (i))
            
            const tickNextFormatted = {
                tickIdx: nextTickIdx,
                liquidity: previous.liquidity,
                liquidityNet: JSBI.BigInt(0),
                liquidityGross: JSBI.BigInt(0),
                isCurrentTick: false
            }

            if(formattedData[nextTickIdx] && direction === 0){
                tickNextFormatted.liquidityNet = JSBI.BigInt(formattedData[nextTickIdx].liquidityNet)
                tickNextFormatted.liquidityGross = JSBI.BigInt(formattedData[nextTickIdx].liquidityGross)
                tickNextFormatted.liquidity = JSBI.subtract(previous.liquidity, previous.liquidityNet)
            } else if(formattedData[nextTickIdx] && direction === 1) {
                tickNextFormatted.liquidityNet = JSBI.BigInt(formattedData[nextTickIdx].liquidityNet)
                tickNextFormatted.liquidityGross = JSBI.BigInt(formattedData[nextTickIdx].liquidityGross)
                tickNextFormatted.liquidity = JSBI.add(previous.liquidity, JSBI.BigInt(formattedData[nextTickIdx].liquidityNet))
            }

            allTicksWithLiquidity.push(tickNextFormatted)

            previous = tickNextFormatted

        }
        return allTicksWithLiquidity
    }
    const lowerFormatted = formatTicks(dir.desc, lowerTicks).reverse()
    const upperFormatted = formatTicks(dir.asc, upperTicks)


    return {
        data: lowerFormatted.concat(tickCurrentFormatted).concat(upperFormatted),
        loading: loading,
        error: Boolean(error)
    }
}