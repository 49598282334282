import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { VisuallyHidden } from '@reach/visually-hidden'


import styled from 'styled-components/macro'

import ethereumLogo from '../../assets/ethereumLogo.png'

import { WrappedTokenInfo } from "../../utils/WrappedTokenInfo";

import { X as CloseIcon } from 'react-feather'

//Components
import { TextWrapper } from '../../theme/components'
import { SearchInput } from '../Input'

//Layout
import Row, { RowBetween, AutoRow } from '../../components/Row'
import Column, { AutoColumn } from '../../components/Column'

//Reat
import React, { useEffect, useRef, useState } from 'react'

//Uniswap
import { Ether, NativeCurrency, Token, Currency } from '@uniswap/sdk-core'

//LIBRARIES
import { useSpring, animated, useTransition } from 'react-spring'

//CONSTANTS
import { COMMON_BASES } from '../../constants/commonBases'

const AnimatedDialogOverlay = animated(DialogOverlay)

const StyledAnimatedDialogOverlay = styled(AnimatedDialogOverlay)`
    &[data-reach-dialog-overlay] {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
`

const StyledDialogContent = styled(DialogContent)`
    &[data-reach-dialog-content] {
        min-height: 80vh;
        max-height: 80vh;
        border-radius: 20px;
        display: flex;
        max-width: 420px;
        align-self: center;
        width: 50vw;
        border: ${({ theme }) => '1px solid ' + theme.blue };
        background-color: ${({ theme }) => theme.darkBlue };
        padding: 0px;
    }
`
const StyledColumn = styled(Column)`
    width: 100%;
`

const StyledAutoColumn = styled(AutoColumn)`
    padding: 20px;
`

const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  color: ${({ theme, disable }) => disable && theme.text3};
  background-color: ${({ theme, disable }) => disable && theme.bg3};
  filter: ${({ disable }) => disable && 'grayscale(1)'};
`

export default function DefiMathModal({ 
    isOpen, 
    onDismiss, 
    data, 
    chainId,
    selectedCurrency,
    onSelect
}: {
    isOpen: boolean
    onDismiss: () => void
    data?: (Token | NativeCurrency)[]
    chainId: number
    selectedCurrency?: Currency
    onSelect: (currency: Currency) => void
}) {

    const inputRef = useRef<HTMLInputElement | null>(null)

    const [module, setModule] = useState<any>()

    const [searchQuery, setSearchQuery] = useState<string>('')

    const bases = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []

    useEffect(() => {
        if(isOpen) setSearchQuery('')
    }, [isOpen])


    return(
        <StyledAnimatedDialogOverlay isOpen={true}>
            <StyledDialogContent>
                <StyledColumn>
                    <StyledAutoColumn gap='16px'>
                        <RowBetween>
                            <TextWrapper>Select a token</TextWrapper>
                            <StyledCloseIcon />
                        </RowBetween>
                        <Row>
                            <SearchInput 
                                type="text"
                                id="token-search-input"
                                placeholder="Search name or paste address"
                                autoComplete="off"
                            />
                        </Row>
                            { bases.length > 0 ? (
                                <AutoRow gap="4px">
                                   { bases.map((currency: Currency, index: number) => {
                                       const isSelected = selectedCurrency?.equals(currency)
                                        return (
                                            <BaseWrapper
                                                onClick={() => !isSelected && onSelect(currency)}
                                                disable={isSelected}
                                                key={index}
                                            >
                                                <TextWrapper fontWeight={500} fontSize={16}>
                                                    {currency.symbol}
                                                </TextWrapper>
                                            </BaseWrapper>
                                        )
                                   }) }
                                </AutoRow>
                            ) : null }
                    </StyledAutoColumn>
                </StyledColumn>
            </StyledDialogContent>
        </StyledAnimatedDialogOverlay>
    )
}