import styled, { keyframes } from 'styled-components/macro'
import { Box } from 'rebass'

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; $borderRadius?: string }>`
    width: ${({ width }) => width ?? '100%'};
    padding: ${({ padding }) => padding ?? '1rem'};
    border-radius: ${({ $borderRadius }) => $borderRadius ?? '16px'};
    border: ${({ border }) => border};
`

export default Card

export const OutlinedCard = styled(Card)`
    border: 1px solid ${({ theme }) => theme.lightBlue};
`

const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    box-shadow: 0 0 0 2px ${color};
  }

  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`

export const FocusedOutlineCard = styled(OutlinedCard)<{ active: boolean; pulsing: boolean; padding?: string }>`
    border-color: ${({ active, theme }) => active && theme.purple};
    padding: ${({ padding }) => padding ? padding : '20px 12px'};
    animation: ${({ pulsing, theme }) => pulsing && pulse(theme.blue)} 0.8s linear;
`

