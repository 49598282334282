import styled from 'styled-components/macro'
import { ColumnCenter } from '../components/Column'
import { ReactNode } from 'react'
import { Text, TextProps } from 'rebass/styled-components'

export function InfoBox({ message, icon }: { message?: string; icon: ReactNode }) {
    return (
      <ColumnCenter style={{ height: '100%', justifyContent: 'center' }}>
        {icon}
        {message && (
          <TextWrapper>
            {message}
          </TextWrapper>
        )}
      </ColumnCenter>
    )
}

export const TextWrapper = styled(Text)<TextProps>`
    font-family: ${({ theme }) => theme.fontFamily};
`

export const TokenIcon = styled.img<{ size?: string }>`
    width: ${({ size }) => size ? size : '24px' };
    height: ${({ size }) => size ? size : '24px' };
`