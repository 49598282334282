import { SyntheticEvent } from "react"
import { format, isBefore, endOfDay, startOfDay, isAfter, isSameDay } from 'date-fns'
import { DatesInterface } from '../../../interfaces'
import styled from 'styled-components'

export default function DayCell({ 
    range, 
    day, 
    preview, 
    isWeekend, 
    isToday, 
    isStartOfWeek, 
    isEndOfWeek, 
    isStartOfMonth, 
    isEndOfMonth, 
    disabled, 
    isPassive,
    onMouseDown, 
    onMouseUp, 
    onMouseEnter,
    onPreviewChange,
    dayDisplayFormat
}: {
    range: DatesInterface
    day: Date
    preview: DatesInterface | null
    isWeekend: boolean
    isToday: boolean
    isStartOfWeek: boolean
    isEndOfWeek: boolean
    isStartOfMonth: boolean
    isEndOfMonth: boolean
    disabled?: boolean
    isPassive: boolean
    onMouseDown: (day: Date) => void
    onMouseUp: (day: Date) => void
    onMouseEnter: (day: Date) => void
    onPreviewChange:  (val?: Date) => void
    dayDisplayFormat: string
} ) {

    const getClassNames = () => {
        return (
            `${isPassive ? 'isPassive ' : ' '}` +
            `${disabled ? 'disabled ' : ' '}` +
            `${isToday ? 'isToday ' : ' '}` +
            `${isWeekend ? 'isWeekend ' : ' '}` +
            `${isStartOfWeek ? 'isStartOfWeek ' : ' '}` +
            `${isEndOfWeek ? 'isEndOfWeek ' : ' '}` + 
            `${isStartOfMonth ? 'isStartOfMonth ' : ' '}` +
            `${isEndOfMonth ? 'isEndOfMonth ' : ' '}`
        )
    }

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if ([13 /* space */, 32 /* enter */].includes(event.keyCode)) {
          if (event.type === 'keydown') onMouseDown(day)
          else onMouseUp(day)
        }
      }

    const renderPreviewPlaceholder = () => {
        if (!preview) return null
       
        const startDate = preview.startDate ? endOfDay(preview.startDate) : null
        const endDate = preview.endDate ? startOfDay(preview.endDate) : null
        const isInRange = (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate))
        const isStartEdge = startDate ? !isInRange && isSameDay(day, startDate) : false
        const isEndEdge = endDate ? !isInRange && isSameDay(day, endDate) : false
  
        return (
          <span className={`${(isInRange ? 'dayInPreview ' : ' ') + (isStartEdge ? 'dayStartPreview ' : ' ') + (isEndEdge ? 'dayEndPreview ' : ' ')}`}></span>
        )
    }

    const renderSelectionPlaceholders = () => {
        let startDate: Date | null = range.startDate
        let endDate = range.endDate
        if (startDate && endDate && isBefore(endDate, startDate)) {
            [startDate, endDate] = [endDate, startDate]
          }
        startDate = startDate ? endOfDay(startDate) : null
        endDate = endDate ? startOfDay(endDate) : null

        // const isInRange = (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate))
        const isInRange = startDate && endDate && isAfter(day, startDate) && isBefore(day, endDate)
        const isStartEdge = startDate ? !isInRange && isSameDay(day, startDate) : false
        const isEndEdge = endDate ? !isInRange && isSameDay(day, endDate) : false

        return (
            <span className={`${(isInRange ? 'inRange ' : ' ') + (isStartEdge ? 'startEdge ' : ' ') + (isEndEdge ? 'endEdge ' : ' ')}`}></span>
        )
    }

    const handleMouseEvent = (event: SyntheticEvent) => {
        if (disabled) {
            onPreviewChange()
            return
        }

        switch (event.type) {
            case 'mouseenter':
                onMouseEnter(day)
                onPreviewChange(day)
                break
            case 'blur':
            case 'mouseleave':
                break
            case 'mousedown':
                onMouseDown(day)
                break
            case 'mouseup':
                event.stopPropagation()
                onMouseUp(day)
                break
            case 'focus': 
                onPreviewChange(day);
                break
        }
    }

    return (
        <Day
            type='button'
            onMouseEnter={handleMouseEvent}
            onMouseLeave={handleMouseEvent}
            onFocus={handleMouseEvent}
            onMouseDown={handleMouseEvent}
            onMouseUp={handleMouseEvent}
            onKeyDown={handleMouseEvent}
            onKeyUp={handleKeyEvent}
            disabled={disabled}
            className={getClassNames()}
            {...(disabled || isPassive ? { tabIndex: -1 } : {tabIndex : 0})}
        >
            {renderSelectionPlaceholders()}
            {renderPreviewPlaceholder()}
            <DayNumber className={'dayNumber'}>
                <StyledSpan isToday={isToday}>{format(day, dayDisplayFormat)}</StyledSpan>
            </DayNumber>
        </Day>
    )
}

const Day = styled.button.attrs((props: any) => ({
    disabled: props.disabled,
    className: props.className
}))`
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    background: transparent;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 2.700em;
    height: 2.7000em;
    text-align: center;
    color: #1d2429;
    &:focus {
      outline: 0;
    }
    &:is(.isToday):not(.isPassive) {
        .inRange, .startEdge, .endEdge {
            & ~ .dayNumber span:after {
                background: green;
            }
        }
    }
    &:not(.isPassive){
        .inRange, .startEdge, .endEdge {
            & ~ .dayNumber{
              span {
                color: white;
              }
            }
        }
    }
    .inRange, .startEdge, .endEdge {
        background: rgb(61, 145, 255);
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        bottom: 5px;
        pointer-events: none;
    }
    
    .startEdge {
        border-top-left-radius: 1.042em;
        border-bottom-left-radius: 1.042em;
        left: 2px;
    }

    .endEdge{
        border-top-right-radius: 1.042em;
        border-bottom-right-radius: 1.042em;
        right: 2px;
    }

    &:is(.isStartOfMonth, .isStartOfWeek) {
        .inRange, .endEdge {
          border-top-left-radius: 1.042em;
          border-bottom-left-radius: 1.042em;
          left: 2px;
        }
    }

    &:is(.isEndOfMonth, .isEndOfWeek) {
        .inRange,  .startEdge {
          border-top-right-radius: 1.042em;
          border-bottom-right-radius: 1.042em;
          right: 2px;
        }
    }

    &:is(.isStartOfMonth, .isStartOfWeek) {
        .dayInPreview, .dayEndPreview {
          border-top-left-radius: 1.333em;
          border-bottom-left-radius: 1.333em;
          border-left-width: 1px;
          left: 0px;
        }
    }
      
    &:is(.isEndOfMonth, .isEndOfWeek) {
        .dayInPreview, .dayStartPreview {
         border-top-right-radius: 1.333em;
         border-bottom-right-radius: 1.333em;
         border-right-width: 1px;
         right: 0px;
       }
    }

    .dayStartPreview, .dayInPreview, .dayEndPreview {
        background: rgba(255, 255, 255, 0.09);
        position: absolute;
        top: 3px;
        left: 0px;
        right: 0px;
        bottom: 3px;
        pointer-events: none;
        border: 0px solid rgb(61, 145, 255);
        z-index: 1;
        pointer-events: none;
    }

    .dayStartPreview{
        border-top-width: 1px;
        border-left-width: 1px;
        border-bottom-width: 1px;
        border-top-left-radius: 1.333em;
        border-bottom-left-radius: 1.333em;
        left: 0px;
    }

    .dayInPreview{
        border-top-width: 1px;
        border-bottom-width: 1px;
      }
      
    .dayEndPreview{
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-top-right-radius: 1.333em;
        border-bottom-right-radius: 1.333em;
        right: 2px;
        right: 0px;
    }

    &:is(.isPassive) {
        pointer-events: none;
        .dayNumber span{
          color: #696969;
        }
        .inRange, .startEdge, .endEdge, .selected, .dayStartPreview, .dayInPreview, .dayEndPreview{
          display: none;
        }
    }

`

const DayNumber = styled.span.attrs((props) => ({
    className: props.className
}))`
    font-weight: 300;
    outline: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: white;
    }
`

const StyledSpan = styled.span.attrs((props: { isToday: boolean }) => ({
    isToday: props.isToday,
}))`
    font-weight: ${props => props.isToday && 500 };
    &:after {
        content: ${props => props.isToday && '""'};
        position: ${props => props.isToday && 'absolute'};
        bottom: ${props => props.isToday && '4px'};
        left: ${props => props.isToday && '50%'};
        transform: ${props => props.isToday && 'translate(-50%, 0)'};
        width: ${props => props.isToday && '18px'};
        height: ${props => props.isToday && '2px'};
        border-radius: ${props => props.isToday && '2px'};
        background: ${props => props.isToday && '#3d91ff'};
    }
`





