import { axisBottom, AxisScale, select } from 'd3'
import { useRef, useEffect } from 'react'

export default function AxisBottom({ 
    scale, 
    position 
}: {
    scale: AxisScale<Number>, 
    position: number, 
} ) {
    const axisRef = useRef<SVGGElement>(null)

    useEffect(() => {
        if(!axisRef.current) return
        const xAxis = axisBottom(scale).ticks(5).tickSizeOuter(0)

        const selection = select(axisRef.current)
        selection.call(xAxis).call((g) => g.select('.domain').remove())
    }, [scale])

    return(<g transform={`translate(${[0, position].join(',')})`} ref={axisRef} opacity={0.7}/>)
}