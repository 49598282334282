//COMPONENTS
import { TextWrapper } from '../../theme/components'
import { AutoColumn } from '../Column'
import Row from '../Row'

//LIBS
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

//CORE
import { CurrencyAmount, Token, Percent } from '@uniswap/sdk-core'

//REACT
import { useMemo } from 'react'

//INTERFACES
import { FiatGasFees } from '../../interfaces'

interface YieldResultProps {
    liquidityIn: CurrencyAmount<Token> | undefined
    liquidityOut: CurrencyAmount<Token> | undefined
    earnedfees: CurrencyAmount<Token> | undefined
    gasFees: FiatGasFees | undefined
}

export default function YieldResult({ 
    liquidityIn, 
    liquidityOut, 
    earnedfees, 
    gasFees 
}: YieldResultProps ) {
    const theme = useContext(ThemeContext)

    const apyPercent = useMemo(() => {
        if(!liquidityIn || !liquidityOut || !earnedfees || !gasFees || !gasFees.add || !gasFees.remove) return

        return new Percent(
            liquidityOut.add(earnedfees).quotient,
            liquidityIn.add(gasFees.add).add(gasFees.remove).quotient
        ).subtract(new Percent(1))
    }, [earnedfees, gasFees, liquidityIn, liquidityOut])

    const profit = useMemo(() => {
        if(!liquidityIn || !liquidityOut || !earnedfees || !gasFees || !gasFees.add || !gasFees.remove) return

        return liquidityOut.add(earnedfees)
            .subtract(liquidityIn)
            .subtract(gasFees.add)
            .subtract(gasFees.remove)
    }, [earnedfees, gasFees, liquidityIn, liquidityOut])
    

    return (
        <StyledRow paddingX={4} paddingY={1} backgroundColor={theme.blue} borderRadius={'20px'}>
            <AutoColumn>
                <TextWrapper fontSize={1} color={theme.lightBlue}>
                    YIELD:
                </TextWrapper>
                <TextWrapper>
                    Profit: ${profit?.toFixed(2)}
                </TextWrapper>
                <TextWrapper>
                    APY: {apyPercent?.toFixed(2)}%
                </TextWrapper>
            </AutoColumn>
        </StyledRow>
    )
}

const StyledRow = styled(Row)`
    height: 100%
`