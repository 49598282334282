import { AutoColumn } from '../Column'
import Row  from '../Row'
import { OutlinedCard } from '../Card' 
import styled from 'styled-components/macro'
import { Minus, Plus } from 'react-feather'
import { TextWrapper } from '../../theme/components'
import { BaseButtonChildren } from '../../components/Buttons'
import { NumericalInput } from '../Input'

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-gap: 10px;
`

export default function TimeInRangeOptions({
   onUserInput,
   timeInRange
  }: {
    onUserInput: (userInput: string) => void,
    timeInRange: string | undefined
  } ) {


    return (
        <Row marginY={1}>
            <AutoColumn style={{width: '100%', marginBottom: '14px'}}>
                <TextWrapper fontSize={2} paddingTop={2} paddingBottom={3}>
                    Set time in range:
                </TextWrapper>
                <OutlinedCard width={'70%'} margin={'8px auto'}>
                        <InputRow>
                            <BaseButtonChildren>
                                <Minus size={16}/>
                            </BaseButtonChildren>
                            <NumericalInput 
                                onUserInput={onUserInput} 
                                placeholder={'%'} 
                                value={timeInRange ? timeInRange + '%': '%'}
                            />
                            <BaseButtonChildren>
                                <Plus size={16} />
                            </BaseButtonChildren>
                        </InputRow>
                </OutlinedCard>
            </AutoColumn>
        </Row>  
    )
}

