import { useEffect, useState, useCallback } from 'react'
import { isValid, format, Locale, parse } from 'date-fns'
import styled from 'styled-components/macro'

const StyledSpan = styled.span`
  background: ${({ theme }) => theme.darkBlue };
`

export default function DateInput({
    value, 
    dateDisplayFormat, 
    dateOptions, 
    placeholder, 
    onChange ,
    onFocus,
    focused,
    className
}: {
  value: Date | null
  dateDisplayFormat: string
  dateOptions: { locale: Locale }
  placeholder?: string
  onChange: (date: Date) => void
  onFocus: () => void
  focused: boolean
  className?: string
}): JSX.Element {

    const formatDate = useCallback(() => {
        if (value && isValid(value)) {
          return format(value, dateDisplayFormat, dateOptions)
        }
        return ''
    }, [dateDisplayFormat, dateOptions, value])


    const [stateValue, setStateValue] = useState<string>(formatDate())
 
    useEffect(() => {
      if (focused === false) {
        setStateValue(formatDate())
      }
    }, [focused, formatDate, value])


    const [changed, setChanged] = useState(false)
    const [invalid, setInvalid] = useState(false)

    const onChangeHandler = (e: any) => {
        setStateValue(e.target.value)
        setChanged(true)
        setInvalid(false)
    }

    const update = (value: string) => {
        if (invalid || !changed || !value) {
          return
        }
    
        const parsed = parse(value, dateDisplayFormat, new Date(), dateOptions);
        if (isValid(parsed)) {
          setChanged(false)
          onChange(parsed)
        
        } else {
          setInvalid(true)
        }
    }

    const onKeyDown = (e: any) => {    
        if (e.key === 'Enter') {
          update(stateValue)
        }
    }

    const onBlur = () => {
        update(stateValue)
    }


    return (
      <StyledSpan className={className}>
          <input 
              value={stateValue}
              onChange={onChangeHandler}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onFocus={onFocus}
              >
          </input>
      </StyledSpan>
    )
}



