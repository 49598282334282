//Interfaces
import { BlockData, DatesInterface, Margin } from '../../interfaces'

//ENUMS
import { BlockType, Dates } from '../../constants/enums'

//Libraries
import * as d3Scales from 'd3-scale'
import { ZoomTransform } from 'd3'
import { Calendar, AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'

//React
import { useMemo, useCallback, MouseEvent, useRef, RefObject } from 'react'
import { useEffect, useState } from 'react'

//Uniswap
import { Pool } from '@uniswap/v3-sdk'

//Layout
import Column, { AutoColumn } from '../Column'
import Row, { AutoRow, RowFixed } from '../Row'

//Components
import { TextWrapper } from '../../theme/components'
import Spinner from '../Spinner'
import { InfoBox }from '../InfoBox'
import LiquidityChart from './LiquidityChart'
import { ChartWrapper } from '../Charts'
import { BaseButton, BasePinkButton, DropDownButton } from '../Buttons'
import Zoom from '../Zoom'

//Hooks
import useFetchTicksInfo  from '../../hooks/useFetchTicksInfo'
import { FocusedOutlineCard } from '../Card'
import useGetTicksToFetch from '../../hooks/useGetTicksToFetch'
import useClickOutside from '../../hooks/useClickOutside'

const RowItemWrapper = styled(Row)<{ active?: boolean }>`
    background: ${({ theme, active }) => active ? theme.blue : theme.darkBlue };
    padding: 6px 0;
    opacity: ${({ active }) => active ? 1 : 0.5 };
    border-radius: 16px;
    &:hover {
        border: none
    }
    cursor: pointer;
`

const StyledRow = styled(Row)`
    width: auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
    `};
`

const StyledFlyoutContainer = styled(Row)`
    position: relative;
    z-index: 10;
`

const FlyoutMenu = styled(AutoColumn)`
    font-family:  ${({ theme }) => theme.fontFamily };        
    background: ${({ theme }) => theme.darkBlue };
    border: ${({ theme }) => `1px solid ` + theme.lightBlue };
    border-radius: 20px;
    padding: 14px 10px;
    position: absolute;
    top: 30px;
    left: 0px;
    width: 272px;
    z-index: 99;
`
const FlyoutHeader = styled.div`
  font-weight: 400;
`
const StyledChartInfo = styled(Row)`
    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        flex-direction: column;
    `};
`

const StyledTicksInfo = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2px;
    position: relative;
    z-index: 0;
`

export default function LiquidityChartWrapper({ 
    poolCurrent,
    poolIn,
    poolOut,
    feeTier,
    poolAddress,
    onChosenTicks,
    sorted,
    ticks,
    dates,
    blocks,
    priceChartLoading,
    margin={ top: 10, right: 14, bottom: 20, left: 14 },
    dimensions={ width: 400, height: 120 },
}: {
    poolCurrent: Pool | undefined
    poolIn: Pool | undefined,
    poolOut: Pool | undefined,
    poolAddress: string | undefined
    feeTier: number
    sorted?: boolean
    ticks: [number, number] | null
    onChosenTicks: (ticks: [number, number] | null) => void,
    dates: DatesInterface,
    blocks: { [block in BlockType]?: BlockData },
    margin?: Margin,
    priceChartLoading: boolean,
    dimensions?: { width: number; height: number }

} ) {
    const [initialized, setInitialized] = useState(true)

    const [activeDate, setActiveDate] = useState<Dates>(Dates.TODAY)

    const [datesFlyoutOpen, setDatesFlyoutOpen] = useState(false)

    const [transform, setTransform] = useState<ZoomTransform>()

    const pool = useMemo(()=> {
        return (
            activeDate === Dates.STARTDATE ? poolIn
            : activeDate === Dates.ENDDATE ? poolOut
            : activeDate === Dates.TODAY ? poolCurrent 
            : undefined
        )
    }, [activeDate, poolCurrent, poolIn, poolOut])

    const ticksToFetch = useGetTicksToFetch(feeTier, pool?.tickCurrent, ticks)

    const { data, loading, error } = useFetchTicksInfo({ 
        feeTier, 
        liquidity: pool?.liquidity.toString(), 
        poolAddress,
        tickCenter: pool?.tickCurrent,
        blockNumber:  (
            activeDate === Dates.STARTDATE ? blocks?.IN?.number 
            : activeDate === Dates.ENDDATE ? blocks?.OUT?.number 
            : activeDate === Dates.SLIDERDATE ? blocks?.SLIDER?.number 
            : undefined
        ),
        defaultTicksToFetch: ticksToFetch
    })

    const [ innerWidth, innerHeight ] = useMemo(() => {
        return [ dimensions.width - margin.left - margin.right, dimensions.height - margin.top - margin.bottom]
    }, [margin, dimensions])

    const flyoutNode = useRef<HTMLDivElement>(null)
    const handler = useCallback(() => (setDatesFlyoutOpen(false)), [])
    
    useClickOutside(flyoutNode, handler, datesFlyoutOpen)

    const node = useRef<SVGRectElement>(null)

    return (
        <ChartWrapper>
            <Column>
                <Row justify={'space-between'}>
                    <TextWrapper fontSize={3}>
                        Liquidity
                    </TextWrapper>
                    <StyledRow width={'auto'}>
                        <BaseButton
                            onClick={() => {}}
                            active={true}
                            fontSize={1} 
                            padding={1}>Liquidity 
                        </BaseButton>
                        <BaseButton
                            onClick={() => {}}
                            active={false}
                            fontSize={1} 
                            padding={1}>V2vsV3
                        </BaseButton>
                    </StyledRow>
                    <Zoom svg={node.current} setZoom={setTransform} x1={innerWidth} y1={innerHeight} nullifyPointCords={() => {}}/>
                </Row>
                <StyledChartInfo paddingY={1}>
                    <StyledFlyoutContainer ref={flyoutNode}>
                        <DropDownButton onClick={() => setDatesFlyoutOpen(!datesFlyoutOpen)}>
                            <TextWrapper>
                                { activeDate === Dates.STARTDATE ? 
                                    dates.startDate.toDateString().slice(4, 11) + dates.startDate.toDateString().slice(13, 15)
                                    : activeDate === Dates.ENDDATE ? dates.endDate && 
                                    dates.endDate.toDateString().slice(4, 11) + dates.endDate.toDateString().slice(13, 15)
                                    : activeDate === Dates.SLIDERDATE ? dates.sliderDate &&
                                    dates.sliderDate.toDateString().slice(4, 11) + dates.sliderDate.toDateString().slice(13, 15)
                                    : dates.today.toDateString().slice(4, 11) + dates.today.toDateString().slice(13, 15)
                                }
                            </TextWrapper>
                            { datesFlyoutOpen ? (
                                <FlyoutMenu gap={'md'}>
                                    <FlyoutHeader>
                                        <TextWrapper>
                                            Select chart date
                                        </TextWrapper>
                                    </FlyoutHeader>
                                    <AutoColumn gap={'sm'} justify={'center'}>
                                        <RowItemWrapper
                                            onClick={() => setActiveDate(Dates.STARTDATE)}
                                            active={activeDate === Dates.STARTDATE}
                                            fontSize={1}>
                                                <Row padding={'4px 4px'}>
                                                    <Calendar size={'16px'}/>
                                                    <TextWrapper paddingLeft={1}>
                                                        {dates.startDate.toDateString().slice(4, 11) + dates.startDate.toDateString().slice(13, 15) + ' (Selected range start date)'}
                                                    </TextWrapper>
                                                </Row>
                                        </RowItemWrapper>
                                        { dates.endDate ? (
                                            <RowItemWrapper
                                                onClick={() => setActiveDate(Dates.ENDDATE)}
                                                active={activeDate === Dates.ENDDATE}
                                                fontSize={1}>
                                                    <Row padding={'4px 4px'}>
                                                        <Calendar size={'16px'}/>
                                                        <TextWrapper paddingLeft={1}>
                                                            {dates.endDate && dates.endDate?.toDateString().slice(4, 11) + dates.endDate?.toDateString().slice(13, 15) + ' (Selected range end date)'} 
                                                        </TextWrapper>
                                                    </Row>
                                            </RowItemWrapper>
                                        ) : null }
                                        { dates.sliderDate ? (
                                            <RowItemWrapper 
                                                onClick={() => setActiveDate(Dates.SLIDERDATE)}
                                                active={activeDate === Dates.SLIDERDATE}
                                                fontSize={1}>
                                                    <Row padding={'4px 4px'}>
                                                        <Calendar size={'16px'}/>
                                                        <TextWrapper paddingLeft={1}>
                                                            {dates.sliderDate && dates.sliderDate?.toDateString().slice(4, 11) + dates.sliderDate?.toDateString().slice(13, 15) + ' (Current slider date)'}
                                                        </TextWrapper>
                                                    </Row>
                                            </RowItemWrapper>
                                        ) : null }
                                        <RowItemWrapper 
                                            onClick={() => setActiveDate(Dates.TODAY)}
                                            active={activeDate === Dates.TODAY}
                                            fontSize={1}>
                                                <Row padding={'4px 4px'}>
                                                    <Calendar size={'16px'}/>
                                                    <TextWrapper paddingLeft={1}>
                                                        {dates.today.toDateString().slice(4, 11) + dates.today.toDateString().slice(13, 15) + ' (Today)'}
                                                    </TextWrapper>
                                                </Row>
                                        </RowItemWrapper>
                                    </AutoColumn>
                                </FlyoutMenu>
                            ) : null
                        }
                        </DropDownButton> 
                    </StyledFlyoutContainer>
                        <StyledTicksInfo>
                            <TextWrapper fontSize={1} paddingRight={1} opacity={0.7}>Min/max tick:</TextWrapper>
                            <FocusedOutlineCard 
                                width={'auto'} 
                                active={true} 
                                pulsing={true} 
                                padding={'10px 4px'}
                                ><TextWrapper opacity={0.7}>{ticks ? ticks[0].toLocaleString('en-US') : '00'}</TextWrapper>
                            </FocusedOutlineCard>
                            <FocusedOutlineCard 
                                width={'auto'} 
                                active={true} 
                                pulsing={true} 
                                padding={'10px 4px'}
                                ><TextWrapper opacity={0.7}>{ticks ? ticks[1].toLocaleString('en-US') : '00'}</TextWrapper>
                            </FocusedOutlineCard>
                        </StyledTicksInfo>
                </StyledChartInfo>
            </Column>
            <AutoColumn justify={'center'} style={{ minHeight: 200 }}>
                { loading || priceChartLoading ? <Spinner /> 
                    : error ? (
                        <InfoBox message={'Error fetching Chart Data'} icon={<AlertTriangle size={56}  />} />
                    ) : !initialized ? (
                        <InfoBox message={'Pool has not been initialized'} icon={<AlertTriangle size={56}  />} />
                    ) : !data || !data.length || !pool ? (
                        <InfoBox message={'There is no liquidity data available'} icon={<AlertTriangle size={56}  />} />
                    ) : (
                        <LiquidityChart 
                            data={data}
                            dimensions={dimensions}
                            innerDimensions={{ innerWidth, innerHeight }}
                            margin={margin}
                            sorted={sorted}
                            ticks={ticks}
                            currentTick={pool.tickCurrent}
                            onChosenTicks={onChosenTicks}
                            feeTier={feeTier}
                            transform={transform}
                            ref={node}
                        />
                    )
                }
            </AutoColumn>
        </ChartWrapper>
    )
}


