import styled, { ThemeContext } from 'styled-components/macro'
import { PoolHourData, PriceTooltip } from '../../interfaces'
import { Percent, Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useMemo, useState, useContext, useRef, useEffect, forwardRef, useCallback, MouseEvent, Dispatch, SetStateAction } from 'react'
import SVGSurface from '../SVGSurface'
import { getDomain, getDomainOfDates, accessor } from '../../utils/ChartUtils'
import { ZoomTransform, line, area, D3BrushEvent, scaleTime, scaleLinear, pointer, ScaleLinear } from 'd3'
import Brush from './Brush'
import { bisectDate } from '../../utils/ChartUtils'
import { parseUnits } from '@ethersproject/units'
import { priceToClosestTick, tickToPrice } from '@uniswap/v3-sdk'
import { Price } from '@uniswap/sdk-core'
import { getNearestUsableTick, feeTierToTickSpacing } from '../../utils/V3Utils'
import { compareScaleds, compareArrays } from '../../utils/ChartUtils'

//Chart
import AxisBottom from './XAxis'

import usePrevious from '../../hooks/usePrevious'

/* Given an array of 2 values scale.invert them and find neasrest usable ticks */
function fromScaleToTicks(
    values: [number, number], 
    scale: ScaleLinear<number, number, never>, 
    baseToken: Token, 
    quoteToken: Token, 
    feeTier: number
    ) {

    const quoteUnits = values
            .map((value) => scale.invert(value))
            .map((price) => parseUnits(price.toFixed(6).toString(), quoteToken.decimals).toString())
    
    const currencyAmounts = quoteUnits.map((quoteUnit) => CurrencyAmount.fromRawAmount(quoteToken, quoteUnit))

    const baseAmount = CurrencyAmount.fromRawAmount(baseToken, parseUnits('1', baseToken.decimals).toString())

    const [usableTick1, usableTick2] = currencyAmounts
                            .map((amount) => 
                                priceToClosestTick(new Price(baseToken, quoteToken, baseAmount.quotient, amount.quotient))
                            )
                            .map((closestTick) => 
                                getNearestUsableTick(closestTick, feeTierToTickSpacing(feeTier))

                            )
                            .filter((usableTick): usableTick is number => !!usableTick)
    return [usableTick1, usableTick2]
}

const StyledLine = styled.line`
    stroke-width: 1;
    stroke: ${({ theme }) => theme.pink };
`

const StyledPointCircle = styled.circle`
    fill: ${({theme}) => theme.pink}
`

export const ZoomOverlay = styled.rect`
    fill: transparent;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
`

interface PriceChartProps {
    data: PoolHourData[]
    dimensions: { width: number; height: number }
    innerDimensions:{ innerWidth: number; innerHeight: number}
    margin: { top: number; right: number; bottom: number; left: number }
    sorted: boolean
    ticks: [number, number] | null
    setTooltipData: Dispatch<SetStateAction<PriceTooltip | null>>
    priceCurrent: string | undefined
    baseToken: Currency
    quoteToken: Currency
    feeTier: number
    onChosenTicks: (ticks: [number, number]) => void
    transform: ZoomTransform | undefined
}


const PriceChart = forwardRef<SVGRectElement, PriceChartProps>(({ 
         data, 
         dimensions: { width, height },
         innerDimensions: { innerWidth, innerHeight },
         margin,
         sorted,
         ticks,
         setTooltipData,
         priceCurrent,
         baseToken, 
         quoteToken,
         feeTier,
         onChosenTicks,
         transform
    } , ref ) => {
    const theme = useContext(ThemeContext)
    const [pointCords, setPointCords] = useState<[number, number] | null>()
    const [chartInitialized, setChartInitialized] = useState(false)
    
    const [brushExtent, setBrushExtent] = useState<[number, number] | null>()
    const prevExtent = useRef<[number, number] | null>()
    const prevTicks = usePrevious(ticks)

    const { XScale, YScale } = useMemo(() => {
        const scales = {
            XScale: scaleTime()
                .domain(getDomainOfDates(data, 'periodStartUnix'))
                .range([0, innerWidth]),
            YScale: scaleLinear()
                .domain(getDomain(data, sorted ? 'token1Price' : 'token0Price'))
                .range([innerHeight, 0])
            }

        if(transform) {
            const newScaleX = transform.rescaleX(scales.XScale)
            scales.XScale.domain(newScaleX.domain())
        }

        return scales
    }, [data, innerHeight, innerWidth, sorted, transform])

    /*Set initial values for the Brush extent 25% and 75%*/
    useEffect(() => {
        if(ticks || (!ticks && chartInitialized)) return
        
        const brushes: [number, number] = [
            parseFloat(new Percent(1, 4).multiply(innerHeight).quotient.toString()), 
            parseFloat(new Percent(3, 4).multiply(innerHeight).quotient.toString())
        ]

        const [usableTick1, usableTick2] = fromScaleToTicks(brushes, YScale, baseToken.wrapped, quoteToken.wrapped, feeTier)

        if(usableTick1 && usableTick2) onChosenTicks([usableTick1, usableTick2])
        setChartInitialized(true)
    }, [YScale, baseToken, chartInitialized, feeTier, innerHeight, onChosenTicks, quoteToken, ticks])


    const handleBrushMove = useCallback((event: D3BrushEvent<unknown>) => {
        const { selection } = event
        if(!selection || selection && (selection && selection[0] === selection[1])) {
            setBrushExtent(null); prevExtent.current = null
            return
        }
        
        else if(!prevExtent.current || !compareArrays(prevExtent.current, selection as [number, number])) {

            setBrushExtent(selection as [number, number])
            prevExtent.current = selection as [number, number]

            
            const [usableTick1, usableTick2] = fromScaleToTicks(
                                                    selection as [number, number], 
                                                    YScale, 
                                                    baseToken.wrapped, 
                                                    quoteToken.wrapped, 
                                                    feeTier
                                                )
            if(usableTick1 && usableTick2 &&  !compareArrays(prevTicks, [usableTick1, usableTick2])) {
                onChosenTicks([usableTick1, usableTick2]);

            }
        }        
    }, [YScale, baseToken.wrapped, feeTier, onChosenTicks, prevTicks, quoteToken.wrapped])


    useEffect(() => {
        if(!ticks || (prevTicks && compareArrays(ticks, prevTicks))) return

        const newExtent = ticks
                            .map(tick => tickToPrice(baseToken.wrapped, quoteToken.wrapped, tick))
                            .map(price => YScale(parseFloat(price.toFixed(4)))) as [number, number]
                            
        if(!prevExtent.current || !compareArrays(prevExtent.current, newExtent)) {
            setBrushExtent(newExtent)
            prevExtent.current = newExtent
        }

    }, [YScale, baseToken, prevTicks, quoteToken, ticks])

    const linePath = useMemo(() => {
        return line<PoolHourData>()
            .x(d => XScale(accessor(d, 'periodStartUnix')))
            .y(d => YScale(accessor(d, sorted ? 'token1Price' : 'token0Price')))(data)
            
    }, [XScale, YScale, data, sorted])

    const areaPath = useMemo(() => {
        return area<PoolHourData>()
            .x(d => XScale(accessor(d, 'periodStartUnix')))
            .y0(innerHeight)
            .y1(d => YScale(accessor(d, sorted ? 'token1Price' : 'token0Price')))(data)

    }, [XScale, YScale, data, innerHeight, sorted])

    const node = useRef<SVGGElement>(null)

    const handleMouseMove = (event: MouseEvent<SVGGElement>): void => {
        const point = pointer(event)
        const pointDate = XScale.invert(point[0])

        
        const xPoint = bisectDate(data, pointDate)

        const pointData = data[xPoint]

        const date = pointData.periodStartUnix.toDateString()
        const price = sorted ? parseFloat(pointData.token0Price.toFixed(2)) : parseFloat(pointData.token1Price.toFixed(2))

        const pointX = XScale(pointData.periodStartUnix)
        const pointY = YScale(price)

        setTooltipData({ date, price })
        setPointCords([pointX, pointY])
    }

    const handleMouseLeave = (event: MouseEvent<SVGGElement>) => {
        setTooltipData(null)
        setPointCords(null)
    }

    const handleMouseDown = (event: MouseEvent<SVGGElement>) => {
        setTooltipData(null)
        setPointCords(null)
    }

    return (   
        <SVGSurface chart={'Price'} width={width} height={height}>
            <defs>
                <clipPath id={'clip'}>
                    <rect x={0} y={0} width={innerWidth} height={innerHeight}/>
                </clipPath>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#00fabf" stopOpacity={0.6} />
                        <stop offset="70%" stopColor="#000040" stopOpacity={1}/>
                </linearGradient>
            </defs>
            <g transform={`translate(${[margin.left, margin.top].join(',')})`} 
                onMouseMove={handleMouseMove} 
                onMouseLeave={handleMouseLeave} 
                onMouseDown={handleMouseDown}
                >
                <AxisBottom scale={XScale} location={innerHeight}/>
                <ZoomOverlay x={0} y={0} width={innerWidth} height={height} ref={ref}/>
                <g clipPath={'url(#clip)'} ref={node}>
                    { linePath ? <path d={linePath} stroke={theme.green} strokeWidth={1} fill={'none'}/> : null }
                    { areaPath ? <path d={areaPath} fill={'url(#colorUv)'} fillOpacity={0.7} strokeWidth={2}/> : null }
                    { priceCurrent ?  <StyledLine x1={0} y1={YScale(parseFloat(priceCurrent))} x2={innerWidth} y2={YScale(parseFloat(priceCurrent))}></StyledLine> : null}
                    <Brush 
                        group={node} 
                        margin={margin} 
                        widthExtent={innerWidth} 
                        heightExtent={innerHeight} 
                        brushExtent={brushExtent} 
                        handleBrushMove={handleBrushMove} 
                    />
                </g>
                <StyledPointCircle cx={pointCords ? pointCords[0] : undefined} cy={pointCords ? pointCords[1] : undefined} r={pointCords ? 4 : 0} />
            </g>
        </SVGSurface>
    )
})

export default PriceChart