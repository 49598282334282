import { ResponsiveContainer }from 'recharts'
import styled from 'styled-components/macro'
import { Margin, PoolHourData, Point, PriceTooltip } from '../../interfaces'
import Row, { AutoRow } from '../Row'
import Column, { AutoColumn, ColumnCenter } from '../Column'
import { Ether, Currency } from '@uniswap/sdk-core'
import { priceToClosestTick, tickToPrice, Pool } from '@uniswap/v3-sdk'
import React, { useMemo, TouchEvent, MouseEvent, useState, useRef, useEffect, ReactNode } from 'react'
import Spinner from '../Spinner'
import { TextWrapper, InfoBox } from '../../theme/components'
import { AlertTriangle } from 'react-feather'
import PriceChart from './PriceChart'
import Tooltip from './Tooltip'
import { ChartWrapper } from '../Charts'
import Zoom from '../Zoom'
import { FocusedOutlineCard } from '../Card'
import { ZoomTransform } from 'd3'

const StyledChartInfo = styled(Row)`
    ${({ theme }) => theme.mediaWidth.upToLarge`
    `};

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        flex-direction: column;
    `};
`

const StyledPricesInfo = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2px;
`

export default function PriceWrapperChart({ 
    baseToken, 
    quoteToken, 
    onChosenTicks, 
    sorted,
    ticks,
    pool,
    currentPoolError,
    currentPoolLoading,
    poolHourData, 
    isLoading,
    isError,
    feeTier,
    margin={ top: 10, right: 14, bottom: 20, left: 14 },
    dimensions={ width: 400, height: 120 }
}: {
    baseToken?: Currency
    quoteToken?: Currency | null 
    currentTick?: string
    onChosenTicks: (ticks: [number, number]) => void
    feeTier: number
    ticks: [number, number] | null
    sorted: boolean | undefined
    pool: Pool | undefined
    currentPoolLoading: boolean
    currentPoolError: boolean
    poolHourData?: PoolHourData[]
    isLoading: boolean
    isError: boolean
    margin?: Margin,
    dimensions?: { width: number; height: number }
} ) {
    const [initialized, setInitialized] = useState(true)

    const [toolipData, setTooltipData] = useState<PriceTooltip | null>(null)

    const [transform, setTransform] = useState<ZoomTransform>()
    
    const [ innerWidth, innerHeight ] = useMemo(() => {
        return [ dimensions.width - margin.left - margin.right, dimensions.height - margin.top - margin.bottom]
    }, [margin, dimensions])

    const prices = useMemo(() => {
        if(!ticks || !baseToken || !quoteToken) return

        return ticks.map((tick) => tickToPrice(baseToken.wrapped, quoteToken.wrapped, tick))
    }, [ticks])

    const node = useRef<SVGRectElement>(null)

    return (
        <ChartWrapper>
            <Column>
                <Row justify={'space-between'}>
                    <TextWrapper fontSize={3}>
                        Price
                    </TextWrapper>
                    <Zoom svg={node.current} setZoom={setTransform} x1={innerWidth} y1={innerHeight} nullifyPointCords={() => {}}/>
                </Row>
                <StyledChartInfo paddingY={1}>
                    <Tooltip pool={pool} sorted={sorted} tooltip={toolipData} loading={currentPoolLoading} error={currentPoolError}/>
                    <StyledPricesInfo>
                        <TextWrapper fontSize={1} paddingRight={1} opacity={0.7}>Min/Max price: </TextWrapper>
                        <FocusedOutlineCard 
                            width={'auto'} 
                            active={true} 
                            pulsing={true} 
                            padding={'10px 4px'}
                            ><TextWrapper opacity={0.7}>{prices ? sorted ? prices[0].invert().toSignificant(4) : prices[1].toSignificant(4) : '0.0000'}</TextWrapper>
                        </FocusedOutlineCard>
                        <FocusedOutlineCard 
                            width={'auto'} 
                            active={true} 
                            pulsing={true} 
                            padding={'10px 4px'}
                            ><TextWrapper opacity={0.7}>{prices ? sorted ? prices[1].invert().toSignificant(4) : prices[0].toSignificant(4) : '0.0000'}</TextWrapper>
                        </FocusedOutlineCard>
                    </StyledPricesInfo>
                </StyledChartInfo>
            </Column>
            <AutoColumn justify={'center'} style={{ minHeight: 200 }}>
                { isLoading ? <Spinner /> 
                    : isError ? (
                        <InfoBox message={'Error fetching Chart Data'} icon={<AlertTriangle size={56}  />} />
                    ) : !initialized ? (
                        <InfoBox message={'The price chart will apear here'} icon={<AlertTriangle size={56}  />} />
                    ) : !poolHourData || poolHourData === [] || !baseToken || !quoteToken || typeof sorted === 'undefined' ? (
                        <InfoBox message={'There is no price data available'} icon={<AlertTriangle size={56}  />} />
                    ) : (
                        <PriceChart 
                            data={poolHourData}
                            dimensions={dimensions}
                            innerDimensions={{ innerWidth, innerHeight }}
                            margin={margin}
                            sorted={sorted}
                            ticks={ticks}
                            setTooltipData={setTooltipData}
                            priceCurrent={sorted ? pool?.token0Price.toFixed(4) : pool?.token1Price.toFixed(4)}
                            baseToken={baseToken}
                            quoteToken={quoteToken}
                            feeTier={feeTier}
                            onChosenTicks={onChosenTicks}
                            transform={transform}
                            ref={node}
                        />
                    )
                }
            </AutoColumn>
        </ChartWrapper>
    )
}







