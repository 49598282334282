import { TouchEvent, MouseEvent, ReactNode } from 'react'

export default function SVGSurface({
    chart, 
    width, 
    height, 
    children, 
    handleMouseLeave, 
    handleMouseMove, 
}: {
    chart: string
    width?: number | string
    height?: number | string 
    children?: ReactNode
    handleMouseLeave?: (e: TouchEvent<SVGGElement> | MouseEvent<SVGGElement>) => void
    handleMouseMove?: (e: TouchEvent<SVGGElement> | MouseEvent<SVGGElement>) => void
} ) {

    return (
        <svg
            width="100%" 
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
            onMouseLeave={handleMouseLeave} 
            onMouseMove={handleMouseMove}
            >
            <title hidden>{ chart }</title>
            <desc>{ chart } chart with data fetched from The Graph</desc>
            {children}
        </svg>
    )
}



