import styled from 'styled-components/macro'
import { Button as RebassButton, ButtonProps as ButtonPropsOriginal } from 'rebass/styled-components'
import { ChevronDown } from 'react-feather'
import { darken } from 'polished'

export const BaseButton = styled(RebassButton)<{ active?: boolean }>`
    background-color: ${({ theme, active }) => active ? theme.blue : theme.lightBlue };
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    max-height: 36px;
    outline: none;
    will-change: transform;
    transition: transform 450ms ease;
    transform: perspective(1px) translateZ(0);
    box-shadow: 2px 2px 2px 2px rgba(0,255,255,0.7); 
    border: ${({ theme, active }) => active ? `1px solid ${theme.darkBlue}` : '1px solid transparent'};

    &:focus {
        background-color: ${({ theme, active }) => !active && darken(0.08, theme.lightBlue)};
    }
    &:hover {
        background-color: ${({ theme, active }) => !active && darken(0.08, theme.lightBlue)};
    }
`

export const BasePinkButton = styled(BaseButton)`
    background-color: ${({ theme, active }) => active ? theme.pink : theme.blue };
`

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>

export function DropDownButton({children, ...rest}: ButtonProps ) {
    return (
        <BaseButton padding={2} {...rest}>
            {children}
            <ChevronDown size={20}/>
        </BaseButton>
    )
}

export function BaseButtonChildren({children, ...rest}: ButtonProps ) {
    return (
        <BaseButton padding={2} {...rest}>
            {children}
        </BaseButton>
    )
}
