import { Currency, Token } from '@uniswap/sdk-core'
import { SupportedChainId } from './chains'
import { 
    nativeOnChain,
    WRAPPED_NATIVE_CURRENCY,
    DAI,
    USDC as USDC_MAINNET,
    USDT,
    WBTC, 
    DAI_ARBITRUM_ONE,
    USDC_ARBITRUM,
    USDT_ARBITRUM_ONE,
    WBTC_ARBITRUM_ONE,
    DAI_OPTIMISM,
    USDC_OPTIMISM,
    USDT_OPTIMISM,
    WBTC_OPTIMISM,
    WETH_POLYGON,
    USDC_POLYGON,
    USDT_POLYGON,
    DAI_POLYGON,
    WBTC_POLYGON
 } from './tokens'


type ChainCurrencyList = {
    readonly [chainId: number]: Currency[]
}

/**
 * Shows up in the currency select for add liquidity
 */
 export const COMMON_BASES: ChainCurrencyList = {
    [SupportedChainId.MAINNET]: [
      nativeOnChain(SupportedChainId.MAINNET),
      DAI,
      USDC_MAINNET,
      USDT,
      WBTC,
      WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
    ],
    [SupportedChainId.ARBITRUM_ONE]: [
      nativeOnChain(SupportedChainId.ARBITRUM_ONE),
      DAI_ARBITRUM_ONE,
      USDC_ARBITRUM,
      USDT_ARBITRUM_ONE,
      WBTC_ARBITRUM_ONE,
      WRAPPED_NATIVE_CURRENCY[SupportedChainId.ARBITRUM_ONE] as Token,
    ],
    [SupportedChainId.OPTIMISM]: [
      nativeOnChain(SupportedChainId.OPTIMISM),
      DAI_OPTIMISM,
      USDC_OPTIMISM,
      USDT_OPTIMISM,
      WBTC_OPTIMISM,
    ],
    [SupportedChainId.POLYGON]: [
      nativeOnChain(SupportedChainId.POLYGON),
      WETH_POLYGON,
      USDC_POLYGON,
      DAI_POLYGON,
      USDT_POLYGON,
      WBTC_POLYGON,
    ]
  }
  