export const MIN_TICK: number = -887272
export const MAX_TICK: number = -MIN_TICK

export enum FeeAmount {
    LOWEST = 100,
    LOW = 500,
    MEDIUM = 3000,
    HIGH = 10000
}

export const FeeTierToPercentage = {
  [FeeAmount.LOWEST]: '0.01',
  [FeeAmount.LOW]: '0.05',
  [FeeAmount.MEDIUM]: '0.3',
  [FeeAmount.HIGH]: '1'
}

export const addLiquidity100Gas = 571721
export const addLiquidity500GasEth = 490782