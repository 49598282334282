import { useEffect, useMemo, useState } from 'react'
import { FetchResult } from '../interfaces'


export default function useFetchMultiple(urls?: (string | null | undefined)[]): FetchResult[] {
    const [isLoading, setIsLoading] = useState<boolean[]>([])
    const [isError, setIsError] = useState<boolean[]>([])
    const [data, setData] = useState<any[]>([])

    useEffect(() => {
        let isActive = true;
        async function fetchData() {
            if(!urls) return
            const loadings: boolean[] = []
            const errors: boolean[] = []
            const datas: any[] = []
            
            for(let i = 0; i < urls.length; i++) {
                const url = urls[i]
                if(!url) {
                    loadings[i] = false
                    errors[i] = false
                    datas[i] = null
                    continue
                }

                try {
                    loadings[i] = true
                    const response = await fetch(url)
                    if(!response.ok) {
                        const error = response.statusText;
                        return Promise.reject(error);
                    }
                    const responseData = await response.json()
                    datas[i] = responseData
                    errors[i] = false
                } catch(err) {
                    console.debug(`Error fetching ${urls[i]}: `, err)
                    errors[i] = true
                    datas[i] = null
                } finally {
                    loadings[i] = false
                }
            }
            if (isActive) {
                setIsLoading(loadings)
                setIsError(errors)
                setData(datas)
            }
        }
        fetchData()
        return () => {
            isActive = false;
        }
    }, [urls])
    
    return useMemo(() => {
        return data.map((_, i) => {
            return {
                isError: isError[i],
                isLoading: isLoading[i],
                data: data[i]
            }
        })
    }, [data, isError, isLoading])
}