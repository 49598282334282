import { ColumnCenter } from '../Column'
import { TextWrapper } from '../../theme/components'
import { ReactNode } from 'react'

export function InfoBox({ message, icon }: { message?: string; icon: ReactNode }) {
    return (
      <ColumnCenter style={{ height: '100%', justifyContent: 'center' }}>
        {icon}
        {message && (
          <TextWrapper>
            {message}
          </TextWrapper>
        )}
      </ColumnCenter>
    )
}