export enum FocusedToken {
    BASE,
    QUOTE
}

export enum BlockType {
    IN = 'IN',
    OUT = 'OUT',
    SLIDER = 'SLIDER'
}

export enum Positions {
    IN = 'IN',
    OUT = 'OUT',
    SLIDER = 'SLIDER'
}

export enum Dates {
    'STARTDATE',
    'ENDDATE',
    'SLIDERDATE',
    'TODAY'
}
