import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core'

export default function useOnChainGetBlockNumber() {

    const [ lastMinedBlockNumber, setBlockNumber ] = useState<number>()
    const { library } = useWeb3React()
    
    useEffect(() => {
      if(!library) return
      
      async function getBlockNumber() {
        const blockNumber = await library.getBlockNumber()
        if(blockNumber) setBlockNumber(blockNumber)
      }
  
      getBlockNumber()
  
    }, [library])
  
    return lastMinedBlockNumber
  }