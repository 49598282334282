import styled from 'styled-components/macro'
import { useState, useRef, useCallback } from 'react'
import { ReactComponent as MenuIcon } from '../../assets/svg/menu.svg'
import { InfoBox } from '../../theme/components'
import { Info, Coffee, FileText, MessageCircle, GitHub, Twitter } from 'react-feather'
import {  HeaderModals } from '../Header'
import useClickOutside from '../../hooks/useClickOutside'

const StyledMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    text-align: left;

`

const StyledMenuButton = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px 6px;   
    background-color: ${({ theme }) => theme.darkBlue };
    color: ${({ theme }) => theme.white };
    border-radius: 16px;
    :hover {
        cursor: pointer;
        outline: none;
    }

    svg {
        margin-top: 7px;
    }
`

const MenuFlyout = styled.div`
    background-color: ${({ theme }) => theme.darkBlue };
    min-width: 196px;
    max-height: 350px;
    border-radius: 12px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    position: absolute;
    top: 3rem;
    right: 0;
    z-index: 100;
    border: ${({ theme }) => `1px solid ` + theme.lightBlue };
`

// const MenuItem = styled(StyledLink)`
//     display: flex;
//     justify-content: space-between;
//     padding: 0.5rem 0.5rem;

//     > svg {
//         margin-right: 8px;
//     }
// `

interface MenuProps extends React.HTMLAttributes<HTMLElement> {
    active:  HeaderModals; 
    manageModal: (modal:  HeaderModals) => void
}

export default function Menu({ active, manageModal }: MenuProps ) {
    const open = active ===  HeaderModals.MENU ? true : false
    const node = useRef<HTMLDivElement>(null)
    const handler = useCallback(() => (manageModal( HeaderModals.NONE)), [manageModal])
    
    useClickOutside(node, handler, open)
    
    return (
        <StyledMenu ref={node} onClick={() => manageModal( HeaderModals.MENU)}>
            <StyledMenuButton>
                <MenuIcon></MenuIcon>
            </StyledMenuButton>
            {
                open && (
                    <MenuFlyout onMouseLeave={() => manageModal( HeaderModals.NONE)}>
                        <InfoBox message={'About'} icon={<Info opacity={0.6} size={16}/>} />
                    </MenuFlyout>
                )
            }
        </StyledMenu>
    )
}