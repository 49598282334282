import { getUnixTime, set } from 'date-fns'
import { useMemo } from 'react'
import useFetch from './useFetch'


interface HistoryGas {
    gasPrice: {
        open: number
        close: number
        low: number
        high: number
    },
    avgGas: number,
    timestamp: string,
    samples: number
}

export default function useFetchGasPrices(timestamp: number | undefined) {

    const url = `https://owlracle.info/eth/history?apikey=a11d3fd38aaf4c339099a49b8661cdbd&to=${timestamp}&timeframe=120&candles=24`

    const { data, loading, error } = useFetch(url)

    const avg = useMemo(() => {
        if(!data) return 
       return data.reduce((acc: number, curr: HistoryGas) => {
            acc += (curr.gasPrice.high + curr.gasPrice.low) / 2
            return acc
        }, 0) / data.length
    }, [data])

   

    return {
        data: avg,
        loading,
        error
    }
}