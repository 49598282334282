
//COMPONENTS
import { InfoBox }from '../InfoBox'
import { TextWrapper } from '../../theme/components'
import { AutoColumn } from '../Column'
import Row from '../Row'

//LIBS
import { useContext, useEffect } from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'
import { parseUnits } from '@ethersproject/units'

//CORE
import { CurrencyAmount, Token } from '@uniswap/sdk-core'

//HOOKS
import useTheGraphUSDCPrice from '../../hooks/useTheGraphUSDCPrice'
import useFetchGasPrices from '../../hooks/useFetchGasPrices'

//REACT
import { useMemo } from 'react'

//INTERFACES
import { BlockData } from '../../interfaces'

//ENUMS
import { BlockType } from '../../constants/enums'

//CONSTANTS
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'

//INTERFACES
import { FiatGasFees } from '../../interfaces'



export default function GasFeesResult({ 
    blocks, 
    onNewFiatGasFees, 
    chainId 
}: {
    blocks:  { [block in BlockType]?: BlockData }
    onNewFiatGasFees: ({ add, remove }: FiatGasFees ) => void
    chainId: number
} ) {
    const theme = useContext(ThemeContext)

    const wrappedNativeCurrency = WRAPPED_NATIVE_CURRENCY[1]?.wrapped

    const { data: gasPriceIn, loading: gasPriceInLoading, error: gasPriceInError } = useFetchGasPrices(blocks.IN?.timestamp)

    const { data: gasPriceOut, loading: gasPriceOutLoading, error: gasPriceOutError } = useFetchGasPrices(blocks.SLIDER ? blocks.SLIDER.timestamp : blocks.OUT?.timestamp)

    const nativeCurrencyAmount = useMemo(() => {
        return CurrencyAmount.fromRawAmount(wrappedNativeCurrency, '1')
    }, [wrappedNativeCurrency])
    
    const ethPriceIn = useTheGraphUSDCPrice({ currencyAmount: nativeCurrencyAmount, block: blocks.IN?.number, chainId })

    const ethPriceOut = useTheGraphUSDCPrice({ currencyAmount: nativeCurrencyAmount, block: blocks.SLIDER ? blocks.SLIDER.number : blocks.OUT?.number, chainId })

    const increaseLiquidityGas = useMemo(() => {
        if(!gasPriceIn || !ethPriceIn) return
 
        const gas = (gasPriceIn).toFixed(6)
        const gasFees = CurrencyAmount.fromRawAmount(wrappedNativeCurrency, parseUnits(gas, "gwei").toString())

        return ethPriceIn.quote(gasFees).multiply(400000)
    }, [ethPriceIn, gasPriceIn, wrappedNativeCurrency])
    

    const removeLiquidityGas = useMemo(() => {
        if(!gasPriceOut || !ethPriceOut) return
        
        const gas = (gasPriceOut).toFixed(6)
        const gasFees = CurrencyAmount.fromRawAmount(wrappedNativeCurrency, parseUnits(gas.toString(), "gwei").toString())

        return ethPriceOut.quote(gasFees).multiply(400000)
    }, [ethPriceOut, gasPriceOut, wrappedNativeCurrency])

    useEffect(() => {
        if(!increaseLiquidityGas || !removeLiquidityGas) return

        onNewFiatGasFees({
            add: increaseLiquidityGas,
            remove: removeLiquidityGas
        })
    }, [increaseLiquidityGas, onNewFiatGasFees, removeLiquidityGas])

    return (
        <StyledRow paddingX={4} paddingY={1} backgroundColor={theme.blue} borderRadius={'20px'}>
            { (gasPriceInError || gasPriceOutError) 
                ? <InfoBox message={'Error fetching historic gas price'} icon={<AlertTriangle size={10}/>}/> : 
                <AutoColumn>
                    <TextWrapper fontSize={1} color={theme.lightBlue}>
                        GAS COSTS IN USD:
                    </TextWrapper>
                    <TextWrapper fontSize={1}>
                        Add liquidity: ${increaseLiquidityGas?.toFixed(2)}
                    </TextWrapper>
                    <TextWrapper fontSize={1}>
                        Remove liquidity: ${removeLiquidityGas?.toFixed(2)}
                    </TextWrapper>
                </AutoColumn> 
            }
        </StyledRow>
    )
}

const StyledRow = styled(Row)`
    height: 100%
`