import { TextWrapper } from '../../theme/components'
import { AutoColumn } from '../Column'
import Row from '../Row'
import styled from 'styled-components/macro'
import { useState, useCallback, Dispatch, SetStateAction, useMemo, MouseEvent } from 'react'
import { PoolHourData, DatesInterface } from '../../interfaces'


const StyledRow = styled(Row)`
    border-radius: 16px;
    height: 20px;
    background: ${({ theme }) => theme.blue };
`

const StyledBox = styled.div`
    border: 1px solid ${({theme}) => theme.lightBlue };
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 8px;
`

export const Input = styled.input`
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 14px;
        width: 14px;
        background-color: rgb(1,179,236);
        border-radius: 100%;
        border: none;
        color: rgb(1,179,236);
        transform: translateY(-42%);
        &:hover,
        &:focus {
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 24px 32px rgba(0, 0, 0, 0.04);
        }
    }
    &::-moz-range-thumb {
        height: 14px;
        width: 14px;
        background-color: rgb(1,179,236);
        border-radius: 100%;
        border: none;
        color: rgb(1,179,236);
    
        &:hover,
        &:focus {
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 24px 32px rgba(0, 0, 0, 0.04);
        }
    }
    &::-ms-thumb {
        height: 14px;
        width: 14px;
        background-color: rgb(1,179,236);
        border-radius: 100%;
        color: rgb(1,179,236);
    
        &:hover,
        &:focus {
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
            0px 24px 32px rgba(0, 0, 0, 0.04);
        }
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &::-webkit-slider-runnable-track {
        background: linear-gradient(90deg, rgba(127,33,232,1), rgba(33,126,233,1));
        height: 2px;
      }
    
    &::-moz-range-track {
        background: linear-gradient(90deg, rgba(127,33,232,1), rgba(33,126,233,1));
        height: 2px;
    }

    &::-ms-track {
        width: 100%;
        border-color: transparent;
        color: transparent;
    
        background: rgba(127,33,232,1);
        height: 2px;
    }
    &::-ms-fill-lower {
        background: rgba(127,33,232,1);
    }
    &::-ms-fill-upper {
        background: rgba(33,126,233,1);
    }

`;


export default function DateSlider({ 
    data,
    date,
    onNewSliderDate
}: { 
    data: PoolHourData[] | undefined,
    date: Date | null
    onNewSliderDate: Dispatch<SetStateAction<DatesInterface>>
}) {

    const onRangeSlide = useCallback((e:  MouseEvent<HTMLInputElement>) => {
        if(!data) return
        const index = parseInt((e.target as HTMLInputElement).value)
        if(index === 0 || index === data.length - 1) {
            onNewSliderDate(current => {
                return {
                    ...current,
                    sliderDate: null
                }
            })
            return
        }
        const date = data[index].periodStartUnix
        onNewSliderDate(current => {
            return {
                ...current,
                sliderDate: date
            }
        })
    }, [data, onNewSliderDate])

    const localDate = useMemo(() => {
        if(date) return date.toDateString().slice(4, 15)
        else if(data) return data[data.length - 1].periodStartUnix.toDateString().slice(4, 15)
        else return 'Select Dates'
    }, [data, date])
        

    return (
        <Row paddingY={2} justify={'center'}>
            <AutoColumn>
                    <label htmlFor="points">
                        <TextWrapper fontSize={1} >
                            Check how your position changed through time!
                        </TextWrapper>
                    </label>
                <Row justify={'space-between'} paddingTop={2}>
                    <StyledRow width={'60%'}>
                        <Input id="points" type="range" min={0} max={data && data.length - 1} defaultValue={0} onMouseUp={onRangeSlide}/>
                    </StyledRow>
                    <StyledBox>
                        <TextWrapper fontSize={1}>
                            {localDate}
                        </TextWrapper>
                    </StyledBox>
                </Row>
            </AutoColumn>
        </Row> 
    )
}

