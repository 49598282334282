import { useQuery, gql } from "@apollo/client";
import { Token } from '@uniswap/sdk-core'
import useGetClients from './useGetClients'

const getTokensPoolData = gql`
    query GetTokensData($baseToken: ID!, $quoteToken: ID!, $fee: BigInt!) {
        pools(where: { token0: $baseToken, token1: $quoteToken, feeTier: $fee }) {
            id
            feeTier
            liquidity
            sqrtPrice
            token0Price	
            token1Price
            tick
        }
    }
`

const getTokensPoolDataByBlock = gql`
    query GetTokensData($baseToken: ID!, $quoteToken: ID!, $fee: BigInt!, $blockNumber: Int!) {
        pools(where: { token0: $baseToken, token1: $quoteToken, feeTier: $fee }, block: { number: $blockNumber }) {
            id
            feeTier
            liquidity
            sqrtPrice
            token0Price	
            token1Price
            tick
        }
    }
`


interface PoolData {
    id: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    token0Price: string
    token1Price: string
    tick: string
}

export function useFetchPoolData({ feeTier, tokenA, tokenB, chainId }: {
    feeTier: number 
    tokenA?: Token | undefined
    tokenB?: Token | undefined
    chainId: number
}): {
    data?: PoolData,
    error: boolean,
    loading: boolean
} {
    const { client } = useGetClients(chainId)
    
    const { loading, error, data } = useQuery(getTokensPoolData, {
        client: client,
        skip: !tokenA || !tokenB,
        fetchPolicy: "no-cache",
        variables: {
            baseToken: tokenA?.address.toLocaleLowerCase(),
            quoteToken: tokenB?.address.toLocaleLowerCase(),
            fee: feeTier,
        },
    })

    if(!data) {
        return {
            data,
            loading,
            error: Boolean(error)
        }
    }

    return {
        loading,
        error: Boolean(error), 
        data: data.pools[0]
    }
}

export function useFetchPoolDataWithBlock({ 
    feeTier, 
    tokenA, 
    tokenB, 
    blockNumber 
}: {
    feeTier: number 
    tokenA?: Token | undefined
    tokenB?: Token | undefined
    blockNumber?: number
} ): {
    data?: PoolData,
    error: boolean,
    loading: boolean
} {

    /* useQuery will skip if there is no blockNumber valid value */
    const { data, loading, error } = useQuery(getTokensPoolDataByBlock, {
        skip: !tokenA || !tokenB || !blockNumber,
        fetchPolicy: "no-cache",
        variables: {
            baseToken: tokenA?.address.toLocaleLowerCase(),
            quoteToken: tokenB?.address.toLocaleLowerCase(),
            fee: feeTier,
            blockNumber: blockNumber ? Number(blockNumber) : null
        },
    })

    if(!data) {
        return {
            data,
            loading,
            error: Boolean(error)
        }
    }

    return {
        loading,
        error: Boolean(error), 
        data: data.pools[0]
    }
}





